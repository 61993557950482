import { Desktop, Mobile } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useEffect } from "react";
import { CloseButton } from "./CloseButton";
import { ContactForm } from "./ContactForm";
import "./sidePaneForm.scss";

type SidePaneFormProps = {
  isOpen: boolean;
  TipsComponent: (() => JSX.Element) | undefined;
  onClose: () => void;
};

function useCloseOnEscape(isOpen: boolean, onClose: () => void) {
  useEffect(() => {
    if (!isOpen) return () => undefined;
    function closeOnEscape(e: KeyboardEvent) {
      if (e.key === "Escape") {
        onClose();
      }
    }
    window.addEventListener("keydown", closeOnEscape, true);
    return () => {
      window.removeEventListener("keydown", closeOnEscape, true);
    };
  }, [isOpen, onClose]);
}

export function SidePaneForm({
  isOpen,
  onClose,
  TipsComponent,
}: SidePaneFormProps) {
  useCloseOnEscape(isOpen, onClose);

  return (
    <>
      <div
        className="side-pane-form-backdrop"
        aria-hidden
        data-visible={isOpen}
        onClick={onClose}
      />
      <div className="side-pane-form" data-open={isOpen}>
        <Desktop>
          <button
            disabled={!isOpen}
            className="side-pane-form__close-button"
            onClick={onClose}
          >
            <img src="/chevron-right.svg" alt="close pane icon" />
          </button>
        </Desktop>
        <Mobile>
          <div className="side-pane-form__close-button-mobile">
            <CloseButton disabled={!isOpen} onClick={onClose} />
          </div>
        </Mobile>
        <div className="side-pane-form__content">
          {TipsComponent && (
            <Mobile>
              <div className="side-pane-form__tips">
                <TipsComponent />
              </div>
            </Mobile>
          )}
          <ContactForm
            hasTips={!!TipsComponent}
            afterSubmit={() => {
              setTimeout(() => onClose(), 2000);
            }}
          />
        </div>
      </div>
    </>
  );
}
