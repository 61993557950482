import "./eligibilite.scss";
import {
  Form,
  InputButtonGroup,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormAction,
  FormBloc,
  FormField,
  FormGrid,
  FormRow,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { InfoBloc } from "@src/InfoBloc";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { StepProps } from "./Steps";

const schema = zod.object({
  certifyCorrectAge: zod.boolean().optional(),
  certifyCorrectVolonte: zod.boolean().optional(),
  certifyNoInvalidity: zod.boolean().optional(),
  certifyNoDependance: zod.boolean().optional(),
  certifyNoDemarche: zod.boolean().optional(),
});

export type EligibiliteFormValues = zod.infer<typeof schema>;

type EligibiliteProps = StepProps<EligibiliteFormValues>;

function NotEligibleBloc() {
  return (
    <FormBloc>
      <InfoBloc variant="accent">
        <p>
          Malheureusement, nous avons le regret de vous informer que vous
          n&apos;êtes pas éligible au contrat sâge autonomie. En effet, pour
          pouvoir souscrire il faut avoir entre 18 ans et 70 ans et ne pas être,
          ni dans un état de dépendance, ni en invalidité, ni avoir engagé une
          demande de reconnaissance de dépendance ou d&apos;invalidité.
        </p>
      </InfoBloc>
    </FormBloc>
  );
}

function NotVolonteBloc() {
  return (
    <FormBloc>
      <InfoBloc variant="accent">
        <p>
        Afin de continuer le parcours de souscription en ligne, il faut impérativement que vous souhaitiez vous couvrir contre le risque de dépendance.
        Si vous avez une question ou si vous souhaitez vous faire accompagner par un conseiller, vous pouvez demander à être rappelé. 
        </p>
      </InfoBloc>
    </FormBloc>
  );
}


function RemarqueBloc() {
  return (
    <FormBloc>
      <InfoBloc variant="accent">
        <p>
          <b>Remarques importantes:</b> Nous attirons votre attention sur le
          fait que dans le cadre du contrat sâge automomie, la prise
          d&apos;effet des garanties dépendance{" "}
          <b>
            sera différée par application d&apos;un délai d&apos;attente de
            trois ans à compter de la date de prise d&apos;effet du contrat si
            la dépendance résulte d&apos;une maladie neurologique ou psychique
            telle que listée en annexe 4 du règlement sâge autonomie
          </b>{" "}
          ou{" "}
          <b>
            d&apos;un délai d&apos;un an si la dépendance résulte d&apos;une
            autre maladie.
          </b>
        </p>
        <br />
        <p>
          Ainsi,{" "}
          <b>
            la survenance d&apos;un état de dépendance durant le délai
            d&apos;attente conduit à considérer que le contrat n&apos;a jamais
            pris effet pour les garanties dépendance et en conséquence est
            résilié de plein droit et automatiquement dans son intégralité.
          </b>{" "}
          Les cotisations perçues nette de frais de gestion sont remboursées au
          membre participant.
        </p>
      </InfoBloc>
    </FormBloc>
  );
}

export const LABEL_CERTIFY_CORRECT_VOLONTE = 
  "Souhaitez-vous vous couvrir en cas de risque de dépendance ?"
export const LABEL_CERTIFY_CORRECT_AGE =
  "Êtes-vous âgé de moins de 18 ans ou de plus de 70 ans à la date de prise d'effet du contrat ?";
export const LABEL_CERTIFY_NO_INVALIDITY =
  "Êtes-vous atteint d'une invalidité ?";
export const LABEL_CERTIFY_NO_DEPENDANCE =
  "Êtes-vous reconnu en état de dépendance ?";
export const LABEL_CERTIFY_NO_DEMARCHE =
  "Avez-vous engagé une démarche de reconnaissance d'invalidité ou de dépendance telle que définie précédemment ?";

export function Eligibilite({ changeStep, data, setData }: EligibiliteProps) {
  const formConfig = useFormConfig<typeof schema, EligibiliteFormValues>({
    schema,
    defaultValues: data,
  });

  const certifyCorrectAge = formConfig.watch("certifyCorrectAge");
  const certifyCorrectVolonte = formConfig.watch("certifyCorrectVolonte");
  const certifyNoInvalidity = formConfig.watch("certifyNoInvalidity");
  const certifyNoDependance = formConfig.watch("certifyNoDependance");
  const certifyNoDemarche = formConfig.watch("certifyNoDemarche");

  const handleSubmit = (submittedData: EligibiliteFormValues) => {
    setData(submittedData);
    changeStep("CONSENT", "forward");
  };

  return (
    <div className="stepper__centered-container consent-step__first-part">
      <div className="stepper__centered-content step__grid consent-step__main">
        <Form
          className="eligibilite-step__form"
          formConfig={formConfig}
          onSubmit={handleSubmit}
        >
          <FormGrid size="large">
            <FormTitle>
              <h1>
                Afin de poursuivre, veuillez vérifier votre éligibilité au
                contrat sâge autonomie
              </h1>
            </FormTitle>
            <FormRow>
              <FormField>
                <InputButtonGroup<EligibiliteFormValues, boolean>
                  name="certifyCorrectVolonte"
                  size="large"
                  label={LABEL_CERTIFY_CORRECT_VOLONTE}
                  options={[
                    { label: "Oui", value: true },
                    { label: "Non", value: false },
                  ]}
                />
              </FormField>
            </FormRow>
            {certifyCorrectVolonte === false && <NotVolonteBloc />}
            {certifyCorrectVolonte && (<>
              <FormRow>
                <FormField>
                  <InputButtonGroup<EligibiliteFormValues, boolean>
                    name="certifyCorrectAge"
                    size="large"
                    label={LABEL_CERTIFY_CORRECT_AGE}
                    options={[
                      { label: "Oui", value: false },
                      { label: "Non", value: true },
                    ]}
                  />
                </FormField>
              </FormRow>


            {certifyCorrectAge === false && <NotEligibleBloc />}
            {certifyCorrectAge && (
              <>
                <FormRow>
                  <FormField>
                    <InputButtonGroup<EligibiliteFormValues, boolean>
                      name="certifyNoInvalidity"
                      size="large"
                      label={
                        <>
                          {LABEL_CERTIFY_NO_INVALIDITY}
                          <span className="eligibilite-step__complement-label">
                            La reconnaissance d&apos;une invalidité telle que
                            définie dans l&apos;annexe 3 du règlement sâge
                            autonomie est déterminée par le bénéfice de la
                            prestation de compensation du handicap (PCH) ou de
                            l&apos;allocation compensatrice pour tierce personne
                            (ACTP) ou d&apos;une pension d&apos;invalidité.
                          </span>
                        </>
                      }
                      options={[
                        { label: "Oui", value: false },
                        { label: "Non", value: true },
                      ]}
                    />
                  </FormField>
                </FormRow>

                {certifyNoInvalidity === false && <NotEligibleBloc />}
                {certifyNoInvalidity && (
                  <>
                    <FormRow>
                      <FormField>
                        <InputButtonGroup<EligibiliteFormValues, boolean>
                          name="certifyNoDependance"
                          size="large"
                          label={
                            <>
                              {LABEL_CERTIFY_NO_DEPENDANCE}
                              <span className="eligibilite-step__complement-label">
                                Être reconnu en état de dépendance signifie que
                                vous êtes rattaché à l&apos;un des Groupes
                                Iso-Ressources (GIR) ouvrant droit à
                                l&apos;Allocation Personnalisée d&apos;Autonomie
                                (APA) (GIR 1, 2, 3 ou 4).
                              </span>
                            </>
                          }
                          options={[
                            { label: "Oui", value: false },
                            { label: "Non", value: true },
                          ]}
                        />
                      </FormField>
                    </FormRow>
                    {certifyNoDependance === false && <NotEligibleBloc />}
                    {certifyNoDependance && (
                      <>
                        <FormRow>
                          <FormField>
                            <InputButtonGroup<EligibiliteFormValues, boolean>
                              name="certifyNoDemarche"
                              size="large"
                              label={LABEL_CERTIFY_NO_DEMARCHE}
                              options={[
                                { label: "Oui", value: false },
                                { label: "Non", value: true },
                              ]}
                            />
                          </FormField>
                        </FormRow>

                        {certifyNoDemarche === false && <NotEligibleBloc />}
                        {certifyNoDemarche && (
                          <>
                            <RemarqueBloc />
                            <FormAction>
                              <Button type="submit">Suivant</Button>
                            </FormAction>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}</>)}
          </FormGrid>
        </Form>
      </div>
    </div>
  );
}
