import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { captureException } from "@sentry/react";
import { environment, MarqueBlanche } from "@src/MarqueBlanche";
import { Stepper } from "./Stepper";
import "@lya-protect/lya-protect-form-library/dist/style.css";
import "./app.scss";
import { LoaderOverlay } from "./LoaderOverlay";
import { ErrorOverlay } from "./ErrorOverlay";
import { Toaster } from "./Toaster";

export type ProjetPreSouscription = {
  contact: {
    birthDate: Date;
    civility: "Monsieur" | "Madame";
    name: string;
    surname: string;
    mail: string;
    mobile: string;
  };
  tarif: {
    name: string;
    simulationCotisation: number;
  };
};

export type ProjetSouscription = {
  assure: {
    civility: "Monsieur" | "Madame";
    name: string;
    surname: string;
    mail: string;
    mobile: string;
    telephone: string | null;
    address: string;
    postalCode: string;
    city: string;
    birthDate: Date;
  };
};

export type ProjetSignature = {
  devis: {
    formule: string;
    montantMensuel: number;
  };
  assure: {
    numeroSecu: string;
    name: string;
    surname: string;
    mail: string;
    civility: "Monsieur" | "Madame";
    mobile: string;
    telephone: string | null;
    address: string;
    postalCode: string;
    city: string;
    birthDate: Date;
  };
  piecesJustificatives: {
    numero: string;
    description: string;
    document?: PJDocument;
  }[];
  rib: {
    beneficiaire: string;
    iban: string;
    bic: string;
  };
  urlSignature?: string;
};

export type PJDocument = {
  url: string;
  fileUploadedName: string;
};

type AppProps = {
  isSouscription?: boolean;
};
export function App({ isSouscription }: AppProps): ReactElement {
  const params = useParams<{ projectId?: string }>();
  const [isLoading, setIsLoading] = useState(!!params.projectId);
  const [hasError, setHasError] = useState(false);
  const [projetPreSouscription, setProjetPreSouscription] = useState<
    ProjetPreSouscription | undefined
  >(undefined);
  const [projetSouscription, setProjetSouscription] = useState<
    ProjetSouscription | undefined
  >(undefined);
  const [projetSignature, setProjetSignature] = useState<
    ProjetSignature | undefined
  >(undefined);
  const [hasFinished, setHasFinished] = useState(false);

  useEffect(() => {
    if (params.projectId !== undefined) {
      if (!isSouscription) {
        axios
          .get<ProjetPreSouscription>(
            `//${process.env.VITE_URL_GATEWAY}/projet/${params.projectId}`
          )
          .then((res) => {
            res.data.contact.birthDate = new Date(res.data.contact.birthDate);
            setProjetPreSouscription(res.data);
            setIsLoading(false);
          })
          .catch(() => {
            setHasError(true);
          });
      } else {
        axios
          .get<{
            etat:
              | "MODALITE_SOUSCRIPTION_EN_ATTENTE:ETAT_PROJET"
              | "DOSSIER_EN_ATTENTE:ETAT_PROJET"
              | "DOSSIER_VALIDATION_EN_ATTENTE:ETAT_PROJET";
          }>(
            `//${process.env.VITE_URL_GATEWAY}/projet/${params.projectId}/souscription`
          )
          .then((res) => {
            if (
              res.data.etat === "MODALITE_SOUSCRIPTION_EN_ATTENTE:ETAT_PROJET"
            ) {
              const data = res.data as unknown as ProjetSouscription;
              data.assure.birthDate = new Date(data.assure.birthDate);
              setProjetSouscription(data);
              setIsLoading(false);
            } else if (res.data.etat === "DOSSIER_EN_ATTENTE:ETAT_PROJET") {
              const data = res.data as unknown as ProjetSignature;
              data.assure.birthDate = new Date(data.assure.birthDate);
              setProjetSignature(data);
              setIsLoading(false);
            } else {
              setHasFinished(true);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            captureException(err, { level: "warning" });
            console.error(err);
            setHasError(true);
          });
      }
    }
  }, []);

  return (
    <MarqueBlanche>
      <LoaderOverlay isGlobal isLoading={isLoading} />
      {!isLoading && (
        <Stepper
          projetPreSouscription={projetPreSouscription}
          projetSouscription={projetSouscription}
          projetSignature={projetSignature}
          setProjetSignature={setProjetSignature}
          setProjetPreSouscription={setProjetPreSouscription}
          hasFinished={hasFinished}
          projectId={params.projectId}
        />
      )}
      <ErrorOverlay
        hasError={hasError}
        title="L'état de votre projet ne permet plus la visualisation en ligne."
        description="Contactez votre conseiller si nécessaire."
        actionLabel="Retour au site"
        action={() => {
          window.location.replace(environment.current.maisonMereUrl);
        }}
      />
      <Toaster />
    </MarqueBlanche>
  );
}
