import classNames from "classnames";
import "./navButton.scss";

type NavButtonProps = {
  className?: string;
  onClick: () => void;
  children: string;
  spacing?: "normal" | "none";
  size?: "small" | "normal";
  withIcon?: boolean;
};

export function NavButton({
  children,
  className,
  onClick,
  spacing,
  withIcon = true,
  size = "normal",
}: NavButtonProps) {
  return (
    <button
      className={classNames(
        "nav-button",
        {
          "nav-button--no-spacing": spacing === "none",
        },
        className
      )}
      data-spacing={spacing}
      data-icon={withIcon}
      data-size={size}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
