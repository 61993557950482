import { ReactNode, useEffect, useState } from "react";
import "./Steps/step.scss";

type StepLoaderProps = {
  children: ReactNode;
};
export function StepLoader({ children }: StepLoaderProps) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <div className="stepper__step-loader" data-loading={isLoading}>
      <div className="step" tabIndex={-1}>
        {children}
      </div>
    </div>
  );
}
