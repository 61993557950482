import classNames from "classnames";
import "./closeButton.scss";

type CloseButtonProps = {
  className?: string;
  disabled?: boolean;
  onClick: () => void;
};

export function CloseButton({
  className,
  disabled,
  onClick,
}: CloseButtonProps) {
  return (
    <button
      disabled={disabled}
      className={classNames("close-button", className)}
      onClick={onClick}
    />
  );
}
