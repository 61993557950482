export function FooterMCAStepBirthDate() {
  return (
    <p>
      <small>
        Les informations recueillies dans le présent formulaire, ont pour
        finalités la réalisation du devis et la gestion du risque. Vous disposez
        des droits d&apos;accès, de rectification, d&apos;opposition, de
        portabilité, d&apos;effacement et de limitation du traitement vous
        concernant. Pour les exercer, vous pouvez directement contacter le
        Délégué à la Protection des Données : par email à l&apos;adresse
        électronique suivante : dpo@mc-alsace.fr ou par courrier à
        l&apos;adresse postale suivante : DPO - Mutuelle Complémentaire
        d&apos;Alsace, 6 route de Rouffach – CS 40062, 68027 COLMAR CEDEX. Pour
        plus d&apos;informations relatives à la gestion de vos données, vous
        pouvez à tout moment consulter notre politique de Protection des données
        sur le site internet https://www.mc-alsace.fr/ ou la demander par
        courrier simple au responsable de traitement de la Mutuelle
        Complémentaire d&apos;Alsace. Vous pouvez introduire une réclamation
        auprès de la Cnil si vous considérez que le traitement de vos données à
        caractère personnel constitue une violation de la réglementation
        applicable en la matière. Le contact se fait à l&apos;adresse suivante :
        CNIL – 3 Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07.
      </small>
      <small>
        Le contrat sâge autonomie vous est proposé par la Mutuelle
        Complémentaire d&apos;Alsace, mutuelle soumise aux dispositions du livre
        II du Code de la Mutualité, dont le siège social est situé 6 route de
        Rouffach CS 40062 - 68027 Colmar Cedex et immatriculée au répertoire
        SIRENE sous le N° SIREN 778 900 027. Elle agit en qualité de
        distributeur de Tutélaire.
      </small>
      <small>
        Mutuelle soumise aux dispositions du livre II du Code de la mutualité,
        dont le siège social est situé 157 avenue de France – 75013 Paris et
        immatriculée sous le numéro Siren 775 682 164.
      </small>
      <small>
        Les garanties dépendance sont assurées et réalisées par la mutuelle
        Tutélaire. Les garanties service et protection juridiques sont assurées
        et réalisées par Solucia Service et Protection Juridiques, société
        anonyme à directoire et conseil de surveillance au capital social de 9
        600 000 € régie par le Code des assurances, dont le siège social est
        situé 111 avenue de France – CS 51519 – 75634 Paris cedex 13 et
        immatriculée au RCS de Paris sous le numéro Siren 481 997 708.
      </small>
      <small>
        Les garanties d&apos;assistance sont assurées et réalisées par
        Ressources Mutuelles Assistances, union d&apos;assistance soumise aux
        dispositions du Livre II du Code de la mutualité, dont le siège social
        est situé 46 rue du Moulin - CS 32427 - 44124 Vertou Cedex et
        immatriculée sous le numéro Siren 444 269 682
      </small>
    </p>
  );
}
