import { Dispatch, SetStateAction } from "react";
import {
  Desktop,
  Mobile,
} from "@lya-protect/lya-protect-form-library/dist/UI/Utils/BreakPoints";
import "./sidePane.scss";
import { SidePaneForm } from "./SidePaneForm";
import { StepName, STEPS } from "./Steps/Steps";

type SidePaneProps = {
  activeStep: StepName;
  hidden?: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export function SidePane({
  activeStep,
  hidden,
  isOpen,
  setIsOpen,
}: SidePaneProps) {
  const TipsComponent = STEPS[activeStep].tipsComponent;
  const hasTips = !!TipsComponent;
  const isMinified = STEPS[activeStep].minifiedSidePane;

  return (
    <>
      <div
        className="side-pane"
        data-minified={isMinified}
        data-hidden={hidden}
      >
        <div className="side-pane__inner">
          <Desktop>
            <>
              {TipsComponent && (
                <div className="side-pane__tips">
                  <TipsComponent />
                </div>
              )}
              <button
                className="side-pane__cta"
                disabled={isOpen}
                onClick={(e) => {
                  e.currentTarget.blur();
                  setIsOpen(true);
                }}
              >
                {!isMinified && (
                  <div className="side-pane__cta-detailed">
                    <h1>Une question ?</h1>
                    <div className="side-pane__call-me-back">
                      <img src="/phone.svg" alt="phone icon" />
                      <h2>Me faire rappeler</h2>
                    </div>
                  </div>
                )}
                {isMinified && (
                  <div className="side-pane__cta-minimal">
                    <img src="/phone.svg" alt="phone icon" />
                  </div>
                )}
              </button>
            </>
          </Desktop>
          <Mobile>
            <button
              className="side-pane__cta"
              data-info={hasTips}
              disabled={isOpen}
              onClick={(e) => {
                e.currentTarget.blur();
                setIsOpen(true);
              }}
            >
              <div className="side-pane__cta-mobile">
                {hasTips && <img src="/info-circle.svg" alt="info icon" />}
                {!hasTips && <img src="/phone.svg" alt="phone icon" />}
              </div>
            </button>
          </Mobile>
        </div>
      </div>

      {!hidden && (
        <SidePaneForm
          TipsComponent={TipsComponent}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
}
