import { NavButton } from "@src/NavButton";

import "./numSecu.scss";
import {
  Form,
  InputMask,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  Emphasis,
  FormAction,
  FormField,
  FormGrid,
  FormRow,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { getYear } from "date-fns";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { StepProps } from "./Steps";

export type NumSecuData = undefined | { numSecu: string };
type NumSecuProps = StepProps<NumSecuData>;

function cleanMaskedInput(val: unknown) {
  if (typeof val !== "string") return undefined;
  return val.replace(/\s/g, "");
}

const NUM_SECU_BY_CIVILITY = {
  Monsieur: "1",
  Madame: "2",
};

const schema = zod
  .object({
    numSecu: zod.preprocess(
      cleanMaskedInput,
      zod
        .string({ required_error: "Champ requis" })
        .length(15, "Numéro de sécurité sociale invalide")
        .regex(/^[1-2]/, { message: "Numéro de sécurité sociale invalide" })
    ),
    yearOfBirth: zod.string(),
    civility: zod.string(),
  })
  .superRefine((data, ctx) => {
    if (!data.numSecu || data.numSecu.length < 3) return;
    if (
      data.numSecu.substring(0, 1) !==
      NUM_SECU_BY_CIVILITY[data.civility as "Monsieur" | "Madame"]
    ) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        path: ["numSecu"],
        message: "Numéro de sécurité sociale invalide",
      });
    }
    if (data.numSecu.substring(1, 3) !== data.yearOfBirth) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        path: ["numSecu"],
        message: "Numéro de sécurité sociale invalide",
      });
    }
  });
type FormValues = zod.infer<typeof schema>;

function getLast2DigitsOfDateYear(date: Date | undefined) {
  if (!date) return undefined;
  return String(getYear(date)).slice(-2);
}

export function NumSecu({
  changeStep,
  data,
  projetSouscription,
  setData,
}: NumSecuProps) {
  const formConfig = useFormConfig<typeof schema, FormValues>({
    schema,
    defaultValues: {
      numSecu: data?.numSecu,
      yearOfBirth: getLast2DigitsOfDateYear(
        projetSouscription?.assure.birthDate
      ),
      civility: projetSouscription?.assure.civility,
    },
  });

  const handleSubmit = (submittedData: FormValues) => {
    setData({
      numSecu: cleanMaskedInput(submittedData.numSecu) as string,
    });
    changeStep("CONSENT_SOUSCRIPTION", "forward");
  };

  return (
    <div className="num-secu-step">
      <div className="stepper__centered-container">
        <div className="stepper__centered-content step__grid step__grid--padding-bottom">
          <NavButton
            onClick={() => {
              changeStep("RECAP_SOUSCRIPTION", "backward");
            }}
          >
            Précédent
          </NavButton>
          <Form
            className="num-secu-step__form"
            formConfig={formConfig}
            onSubmit={handleSubmit}
          >
            <FormGrid>
              <FormTitle>
                <h1>
                  Quel est votre{" "}
                  <Emphasis>numéro de sécurité sociale ? *</Emphasis>
                </h1>
              </FormTitle>
              <FormRow>
                <FormField>
                  <InputMask<FormValues>
                    variant="large"
                    name="numSecu"
                    placeholder="15 chiffres"
                    mask="9 99 99 99 999 999 99"
                    required
                  />
                </FormField>
              </FormRow>
              <FormAction>
                <Button
                  type="submit"
                  disabled={
                    formConfig.formState.isSubmitting ||
                    formConfig.formState.isSubmitSuccessful
                  }
                >
                  Suivant
                </Button>
              </FormAction>
            </FormGrid>
          </Form>
        </div>
      </div>
    </div>
  );
}
