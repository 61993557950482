import { NavButton } from "@src/NavButton";
import {
  Form,
  InputDate,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  Emphasis,
  FormAction,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { getYear } from "date-fns";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { environment } from "@src/MarqueBlanche";
import { StepProps } from "./Steps";

import "./birthDate.scss";

export type BirthDateData = {
  birthDate?: Date;
};
type BirthDateProps = StepProps<BirthDateData>;

const ERROR_BIRTHDATE =
  "La souscription du contrat sâge autonomie est ouverte aux personnes \
âgées entre 18 ans et 70 ans au moment de la date de prise d'effet du contrat.\n\
L'âge atteint est calculé par différence de millésimes entre l'année en cours et \
l'année de naissance.";

const schema = zod.object({
  birthDate: zod.date({ required_error: "Champ requis" }).refine((val) => {
    const diff = getYear(new Date()) - getYear(val);
    return diff >= 18 && diff <= 70;
  }, ERROR_BIRTHDATE),
});
type FormValues = zod.infer<typeof schema>;

export function BirthDate({ changeStep, data, setData }: BirthDateProps) {
  const formConfig = useFormConfig<typeof schema, FormValues>({
    schema,
    defaultValues: data,
  });

  return (
    <div className="birthdate-step">
      <div className="stepper__centered-container">
        <div className="stepper__centered-content step__grid step__grid--padding-bottom">
          <NavButton
            onClick={() => {
              changeStep(
                environment.is.default ? "SITUATION" : "LANDING",
                "backward"
              );
            }}
          >
            Précédent
          </NavButton>
          <Form
            className="situation-step__form"
            formConfig={formConfig}
            onSubmit={(submittedData) => {
              setData(submittedData);
              changeStep("TARIF", "forward");
            }}
          >
            <FormGrid size="large">
              <FormRow>
                <FormField className="birthdate-step__input">
                  <InputDate
                    variant="large"
                    name="birthDate"
                    label={
                      <>
                        Quelle est votre{" "}
                        <Emphasis>date de naissance ? *</Emphasis>
                      </>
                    }
                  />
                </FormField>
              </FormRow>
              <FormAction>
                <Button
                  type="submit"
                  disabled={
                    formConfig.formState.isSubmitting ||
                    formConfig.formState.isSubmitSuccessful
                  }
                >
                  Suivant
                </Button>
              </FormAction>
            </FormGrid>
          </Form>
        </div>
      </div>
    </div>
  );
}
