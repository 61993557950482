import { FooterAMELLISDefault } from "@src/Footer/AMELLIS/FooterAMELLISDefault";
import { FooterAMELLISStepAboutYou } from "@src/Footer/AMELLIS/FooterAMELLISStepAboutYou";
import { FooterAMELLISStepBirthDate } from "@src/Footer/AMELLIS/FooterAMELLISStepBirthDate";
import { FooterAMELLISStepContactInfos } from "@src/Footer/AMELLIS/FooterAMELLISStepContactInfos";
import { FooterAMELLISStepEligibilite } from "@src/Footer/AMELLIS/FooterAMELLISStepEligibilite";
import { FooterAMELLISStepMandatPrelevement } from "@src/Footer/AMELLIS/FooterAMELLISStepMandatPrelevement";
import { FooterAMELLISStepNumSecu } from "@src/Footer/AMELLIS/FooterAMELLISStepNumSecu";
import { FooterAMELLISStepPiecesJustificatives } from "@src/Footer/AMELLIS/FooterAMELLISStepPiecesJustificatives";
import { StepName } from "@src/Steps/Steps";
import React from "react";

type FooterByStepNameProps = {
  stepName: StepName;
};

export function FooterAMELLISByStepName({ stepName }: FooterByStepNameProps) {
  switch (stepName) {
    case "BIRTHDATE":
      return <FooterAMELLISStepBirthDate />;
    case "CONTACT_INFOS":
      return <FooterAMELLISStepContactInfos />;
    case "ELIGIBILITE":
      return <FooterAMELLISStepEligibilite />;
    case "ABOUT_YOU":
      return <FooterAMELLISStepAboutYou />;
    case "NUM_SECU":
      return <FooterAMELLISStepNumSecu />;
    case "MANDAT_PRELEVEMENT":
      return <FooterAMELLISStepMandatPrelevement />;
    case "PIECES_JUSTIFICATIVES":
      return <FooterAMELLISStepPiecesJustificatives />;
    default:
      return <FooterAMELLISDefault />;
  }
}
