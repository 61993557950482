import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { InfoBulle } from "@src/InfoBulle";
import { InfoBulleButton } from "@src/InfoBulleButton";
import { LinkButton } from "@src/LinkButton";
import { NavButton } from "@src/NavButton";
import { PopperButton } from "@src/PopperButton";
import { formatCurrency } from "@src/utils";
import classNames from "classnames";
import { Fragment, useMemo } from "react";
import { ReactComponent as Check } from "@src/svg/check.svg";
import { StepName } from "./Steps";
import { Tarif } from "./Tarif";
import "./tarifDesktop.scss";

function Included() {
  return (
    <p className="tarif-desktop-step__included">
      <Check className="tarif-desktop-step__included-svg" /> Inclus
    </p>
  );
}

function getTableCells(
  tarifs: Tarif[],
  selectTarif: (tarif: Tarif) => () => void,
  openGAssistanceModal: () => void,
  openPJuridiqueModal: () => void
) {
  return [
    [
      <div />,
      <h2>{tarifs[0].name}</h2>,
      <h2>{tarifs[1].name}</h2>,
      <h2>{tarifs[2].name}</h2>,
    ],
    [
      <>
        <h2 className="tarif-desktop-step__cell-big-label">
          Garanties dépendance
        </h2>
        <div className="tarif-desktop-step__cell-label-padded">
          <h3>Capital forfaitaire</h3>
          <p>
            En cas de rattachement au
            <PopperButton
              popperPlacement="left"
              openOnHover
              withArrow
              renderButton={(props) => (
                <InfoBulleButton {...props}>GIR 1, 2, 3 ou 4</InfoBulleButton>
              )}
              renderPopperContent={({ setVisible }) => (
                <InfoBulle onCancel={() => setVisible(false)}>
                  Degré de perte d&apos;autonomie évalué dans le cadre
                  d&apos;une demande d&apos;allocation personnalisée
                  d&apos;autonomie (APA).
                  <br />
                  Les GIR 1 et 2 correspondent à une dépendance lourde, les GIR
                  3 et 4 à une dépendance partielle.
                  <br />
                  <p className="tarif-desktop-step__infobulle-details">
                    Les Groupes Iso-Ressources (GIR) correspondent au niveau de
                    perte d’autonomie (état de dépendance) d’une personne.
                    <br />
                    Ils sont calculés à partir de la grille AGGIR (d’autonomie
                    gérontologie groupes iso-ressources).
                    <br />
                    Les personnes évaluées en (GIR) 1 à 4 peuvent bénéficier de
                    l'APA (Allocation Personnalisée d’Autonomie).
                    <br />
                    Les (GIR) 1 et 2 correspondent à une dépendance lourde et
                    les (GIR) 3 et 4 à une dépendance partielle.
                    <br />
                    Une évaluation de la perte d’autonomie correspondant à un
                    (GIR) 5 ou 6 ne permet pas de bénéficier de l’APA
                    (Allocation Personnalisée d’Autonomie).
                  </p>
                </InfoBulle>
              )}
            />
          </p>
        </div>
      </>,
      <p>1500 €</p>,
      <p>3000 €</p>,
      <p>4500 €</p>,
    ],
    [
      <div className="tarif-desktop-step__cell-label-padded">
        <h3>Rente mensuelle</h3>
        <p>
          En cas de rattachement au
          <PopperButton
            popperPlacement="left"
            openOnHover
            withArrow
            renderButton={(props) => (
              <InfoBulleButton {...props}>GIR 1,2 ou 3</InfoBulleButton>
            )}
            renderPopperContent={({ setVisible }) => (
              <InfoBulle onCancel={() => setVisible(false)}>
                Degré de perte d&apos;autonomie évalué dans le cadre d&apos;une
                demande d&apos;allocation personnalisée d&apos;autonomie (APA).
                <br />
                Les GIR 1 et 2 correspondent à une dépendance lourde, les GIR 3
                et 4 à une dépendance partielle.
                <br />
                <p className="tarif-desktop-step__infobulle-details">
                  Les Groupes Iso-Ressources (GIR) correspondent au niveau de
                  perte d’autonomie (état de dépendance) d’une personne.
                  <br />
                  Ils sont calculés à partir de la grille AGGIR (d’autonomie
                  gérontologie groupes iso-ressources).
                  <br />
                  Les personnes évaluées en (GIR) 1 à 4 peuvent bénéficier de
                  l'APA (Allocation Personnalisée d’Autonomie).
                  <br />
                  Les (GIR) 1 et 2 correspondent à une dépendance lourde et les
                  (GIR) 3 et 4 à une dépendance partielle.
                  <br />
                  Une évaluation de la perte d’autonomie correspondant à un
                  (GIR) 5 ou 6 ne permet pas de bénéficier de l’APA (Allocation
                  Personnalisée d’Autonomie).
                </p>
              </InfoBulle>
            )}
          />
        </p>
      </div>,
      <p>150 €</p>,
      <p>300 €</p>,
      <p>450 €</p>,
    ],
    [
      <>
        <h2>Garanties service et protection juridiques</h2>
        <LinkButton onClick={openPJuridiqueModal}>
          Détails des garanties
        </LinkButton>
      </>,
      <Included />,
      <Included />,
      <Included />,
    ],
    [
      <>
        <h2>Garanties assistance</h2>
        <LinkButton onClick={openGAssistanceModal}>
          Détails des garanties
        </LinkButton>
      </>,
      <Included />,
      <Included />,
      <Included />,
    ],
    [
      <h2>Simulation de cotisations</h2>,
      <div className="tarif-desktop-step__simulation">
        <p>
          <b>{formatCurrency(tarifs[0].simulationCotisation)}</b> TTC / mois
        </p>
        <p>
          soit <b>{formatCurrency(tarifs[0].simulationCotisation * 12)}</b> TTC
          / an
        </p>
        {tarifs[0].simulationCotisation !== null && (
          <Button onClick={selectTarif(tarifs[0])}>Choisir</Button>
        )}
      </div>,
      <div className="tarif-desktop-step__simulation">
        <p>
          <b>{formatCurrency(tarifs[1].simulationCotisation)}</b> TTC / mois
        </p>
        <p>
          soit <b>{formatCurrency(tarifs[1].simulationCotisation * 12)}</b> TTC
          / an
        </p>
        {tarifs[1].simulationCotisation !== null && (
          <Button onClick={selectTarif(tarifs[1])}>Choisir</Button>
        )}
      </div>,
      <div className="tarif-desktop-step__simulation">
        <p>
          <b>{formatCurrency(tarifs[2].simulationCotisation)}</b> TTC / mois
        </p>
        <p>
          soit <b>{formatCurrency(tarifs[2].simulationCotisation * 12)}</b> TTC
          / an
        </p>
        {tarifs[2].simulationCotisation !== null && (
          <Button onClick={selectTarif(tarifs[2])}>Choisir</Button>
        )}
      </div>,
    ],
  ];
}

type TarifDesktopProps = {
  tarifs: Tarif[];
  age: number;
  openModalPJuridique: () => void;
  openModalGassistance: () => void;
  selectTarif: (tarif: Tarif) => () => void;
  changeStep: (
    stepName: StepName,
    transitionDirection: "forward" | "backward"
  ) => void;
};
export function TarifDesktop({
  age,
  openModalGassistance,
  openModalPJuridique,
  tarifs,
  selectTarif,
  changeStep,
}: TarifDesktopProps) {
  const tableContent = useMemo(
    () =>
      getTableCells(
        tarifs,
        selectTarif,
        openModalGassistance,
        openModalPJuridique
      ),
    [tarifs]
  );

  return (
    <div className="tarif-desktop-step">
      <div className="stepper__centered-container">
        <div className="stepper__centered-content step__grid step__grid--padding-bottom ">
          <NavButton
            spacing="none"
            onClick={() => {
              changeStep("BIRTHDATE", "backward");
            }}
          >
            Précédent
          </NavButton>
        </div>
        <div className="stepper__centered-content stepper__centered-content--large step__grid step__grid--padding-bottom ">
          <div className="tarif-desktop-step__grid">
            <div />
            <div className="tarif-desktop-step__header">
              <h1>
                Choisissez votre couverture de garanties dépendance pour une souscription à
                l&apos;âge de{" "}
                <PopperButton
                  popperPlacement="top-end"
                  openOnHover
                  withArrow
                  renderButton={(props) => (
                    <InfoBulleButton size="large" {...props}>
                      {`${age || 0} ans`}
                    </InfoBulleButton>
                  )}
                  renderPopperContent={({ setVisible }) => (
                    <InfoBulle onCancel={() => setVisible(false)}>
                      L&apos;âge atteint étant calculé par différence de
                      millésimes entre l&apos;année en cours et l&apos;année de
                      naissance
                    </InfoBulle>
                  )}
                />
              </h1>
              <LinkButton
                onClick={() => {
                  changeStep("BIRTHDATE", "backward");
                }}
              >
                Modifier ma date de naissance
              </LinkButton>
            </div>
            {tableContent.map(([Label, ...Cols], index) => (
              <Fragment
                key={index} // eslint-disable-line
              >
                <div className="tarif-desktop-step__table-label">{Label}</div>
                <div className="tarif-desktop-step__table-body">
                  {Cols.map((Col, colIndex) => (
                    <div
                      key={colIndex} // eslint-disable-line
                      className={classNames("tarif-desktop-step__table-col", {
                        "tarif-desktop-step__table-col-header": index === 0,
                        "tarif-desktop-step__table-col-body":
                          index !== 0 && index < tableContent.length - 1,
                        "tarif-desktop-step__table-col-footer":
                          index === tableContent.length - 1,
                      })}
                    >
                      {Col}
                    </div>
                  ))}
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
