import classNames from "classnames";
import { ReactNode } from "react";
import "./card.scss";

type CardProps = {
  children: ReactNode;
  className?: string;
  variant?: "normal" | "accent";
};
export function Card({ children, className, variant = "normal" }: CardProps) {
  return (
    <div className={classNames("card", className)} data-variant={variant}>
      {children}
    </div>
  );
}
