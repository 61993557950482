import { useEffect, useState } from "react";
import { CloseButton } from "./CloseButton";
import "./toaster.scss";

type ToastEventDetail = {
  message: string;
  level: "success" | "error";
};
type ToastProps = {
  detail: ToastEventDetail;
  dismountToast: () => void;
};
function Toast({ detail, dismountToast }: ToastProps) {
  const [hidden, setHidden] = useState(false);

  const hideToast = () => {
    setHidden(true);
    setTimeout(() => {
      dismountToast();
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      hideToast();
    }, 5000);
  }, []);

  return (
    <div
      className="toaster__toast"
      data-level={detail.level}
      data-hidden={hidden}
    >
      <div className="toaster__toast-accent" />
      <p className="toaster__toast-content">{detail.message}</p>
      <CloseButton className="toaster__close-button" onClick={hideToast} />
    </div>
  );
}

export function Toaster() {
  const [displayedToasts, setDisplayedToasts] = useState<
    Record<string, ToastEventDetail>
  >({});

  const handleEvent = ((e: CustomEvent<ToastEventDetail>) => {
    setDisplayedToasts((prevDisplayedToasts) => ({
      ...prevDisplayedToasts,
      [Date.now()]: e.detail,
    }));
  }) as EventListener;

  useEffect(() => {
    document.addEventListener("display-toast", handleEvent);
    return () => document.removeEventListener("display-toast", handleEvent);
  }, []);

  return (
    <div className="toaster">
      {Object.entries(displayedToasts).map(([ts, detail]) => (
        <Toast
          key={ts}
          detail={detail}
          dismountToast={() =>
            setDisplayedToasts(
              ({ [ts]: omitted, ...filteredState }) => filteredState
            )
          }
        />
      ))}
    </div>
  );
}

export const displayToast = (
  message: string,
  level: "success" | "error"
): void => {
  const event = new CustomEvent("display-toast", {
    detail: {
      message,
      level,
    },
  });
  document.dispatchEvent(event);
};
