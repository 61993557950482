import { captureException } from "@sentry/react";
import axios from "axios";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import "./autoCompleteCityDialog.scss";

type AutoCompleteCityDialogProps = {
  postalCode?: string;
  className?: string;
  onSelect: (city: string) => void;
};
export function AutoCompleteCityDialog({
  postalCode,
  onSelect,
  className,
}: AutoCompleteCityDialogProps) {
  const [results, setResults] = useState<string[]>([]);
  const lastCallTSRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    setResults([]);
    if (!postalCode) return;
    const callTS = Date.now();
    lastCallTSRef.current = callTS;
    axios
      .get<string[]>(
        `//${process.env.VITE_URL_GATEWAY}/geo/communes/search/${postalCode}`
      )
      .then((res) => {
        // no set if another call has been launched before resolve
        if (lastCallTSRef.current !== callTS) return;
        setResults(res.data);
      })
      .catch((err) => {
        captureException(err);
        console.error(err);
      });
  }, [postalCode]);

  return (
    <div
      className={classNames("auto-complete-city-dialog", className)}
      data-visible={results.length > 0}
    >
      <div className="auto-complete-city-dialog__box" tabIndex={-1}>
        {postalCode &&
          results.map((res) => (
            <button
              key={res}
              onClick={() => onSelect(res)}
              type="button"
              className="auto-complete-city-dialog__item"
            >
              {`${postalCode} - ${res}`}
            </button>
          ))}
      </div>
    </div>
  );
}
