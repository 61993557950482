import { createPortal } from "react-dom";
import { LoaderAnimation } from "./LoaderAnimation";
import "./loaderOverlay.scss";

type LoaderOverlayProps = {
  isLoading?: boolean;
  isGlobal?: boolean;
};
export function LoaderOverlay({ isLoading, isGlobal }: LoaderOverlayProps) {
  if (!isGlobal) {
    return createPortal(
      <div
        className="loader-overlay"
        data-loading={isLoading}
        data-global={isGlobal}
      >
        <LoaderAnimation />
      </div>,
      document.getElementById("root") as HTMLElement
    );
  }
  return (
    <div
      className="loader-overlay"
      data-loading={isLoading}
      data-global={isGlobal}
    >
      <LoaderAnimation />
    </div>
  );
}
