import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import "./errorOverlay.scss";

type ErrorOverlayProps = {
  hasError?: boolean;
  title?: string;
  description?: string;
  actionLabel?: string;
  action: () => void;
};
export function ErrorOverlay({
  hasError,
  action,
  actionLabel,
  description,
  title,
}: ErrorOverlayProps) {
  return (
    <div className="error-overlay" data-hidden={!hasError}>
      <div className="error-overlay__content">
        <h1>{title}</h1>
        <p>{description}</p>
        {actionLabel && action && (
          <Button
            tabIndex={hasError ? 0 : -1}
            variant="outline"
            onClick={action}
          >
            {actionLabel}
          </Button>
        )}
      </div>
    </div>
  );
}
