import { Placement } from "@popperjs/core";
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

interface RenderButtonProps<T extends HTMLElement> {
  ref: Dispatch<SetStateAction<T | null>>;
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}
interface RenderPopperProps {
  setVisible: Dispatch<SetStateAction<boolean>>;
  visible: boolean;
}
interface PopperButtonOrganismProps<T extends HTMLElement> {
  renderButton(props: RenderButtonProps<T>): ReactElement;
  renderPopperContent(props: RenderPopperProps): ReactElement;
  popperClassName?: string;
  portalTarget?: Element;
  popperPlacement?: Placement;
  withArrow?: boolean;
  openOnHover?: boolean;
}

export function injectPopperStyle(
  popperStyle: React.CSSProperties,
  isVisible: boolean
): React.CSSProperties {
  return {
    ...popperStyle,
    visibility: isVisible ? "visible" : "hidden",
    zIndex: 1,
    pointerEvents: isVisible ? "initial" : "none",
  };
}

export const genHandleClickOutside =
  <T extends HTMLElement>(
    visible: boolean,
    setVisible: Dispatch<SetStateAction<boolean>>,
    referenceElement: T | null,
    popperElement: HTMLDivElement | null
  ) =>
  (event: MouseEvent) => {
    if (visible === false) return;
    if (!document.body.contains(event.target as Element)) return;
    if (!referenceElement) return;
    if (referenceElement.contains(event.target as Element)) return;
    if (!popperElement) return;
    if (popperElement.contains(event.target as Element)) return;
    setVisible(false);
  };

export function getModifiers(
  withArrow: boolean | undefined,
  arrowElement: HTMLElement | null
) {
  return withArrow
    ? [
        {
          name: "arrow",
          options: {
            element: arrowElement,
          },
        },
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ]
    : undefined;
}

export const handleButtonClick =
  (visible: boolean, setVisible: (nextValue: boolean) => void) => () => {
    setVisible(!visible);
  };
export const handleButtonMouseEnter =
  (setVisible: (nextValue: boolean) => void) => () => {
    setVisible(true);
  };
export const handleButtonMouseLeave =
  (setVisible: (nextValue: boolean) => void) => () => {
    setVisible(false);
  };

export const isPopperVisible = (
  visible: boolean,
  openOnHover: boolean | undefined,
  hoverVisible: boolean
) => visible || (!!openOnHover && hoverVisible);

export function PopperButton<T extends HTMLElement>({
  renderButton,
  renderPopperContent,
  popperClassName,
  portalTarget = document.getElementById("root") as HTMLElement,
  popperPlacement,
  withArrow,
  openOnHover,
}: PopperButtonOrganismProps<T>): ReactElement {
  const [referenceElement, setReferenceElement] = useState<T | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const [visible, setVisible] = useState(false);
  const [hoverVisible, setHoverVisible] = useState(false);

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: popperPlacement,
      modifiers: getModifiers(withArrow, arrowElement),
    }
  );

  useEffect(() => {
    const handleClickOutside = genHandleClickOutside(
      visible,
      setVisible,
      referenceElement,
      popperElement
    );
    // add listeners on #root so we can avoid closing popper when portalling inside
    const rootNode = document.getElementById("root") as HTMLElement;
    rootNode.addEventListener("click", handleClickOutside);
    return () => {
      rootNode.removeEventListener("click", handleClickOutside);
    };
  }, [visible, referenceElement]);

  const popperIsVisible = isPopperVisible(visible, openOnHover, hoverVisible);

  useEffect(() => {
    if (update) update().finally(() => undefined);
  }, [update, popperIsVisible]);

  return (
    <>
      {renderButton({
        ref: setReferenceElement,
        onClick: handleButtonClick(visible, setVisible),
        onMouseEnter: handleButtonMouseEnter(setHoverVisible),
        onMouseLeave: handleButtonMouseLeave(setHoverVisible),
      })}
      {createPortal(
        <div
          aria-hidden
          ref={setPopperElement}
          style={injectPopperStyle(styles.popper, popperIsVisible)}
          className={popperClassName}
          {...attributes.popper}
          onClick={(e) => e.stopPropagation()}
        >
          {renderPopperContent({ setVisible, visible: popperIsVisible })}
          <div ref={setArrowElement} style={styles.arrow}>
            {withArrow && (
              <div
                className="info-bulle__arrow"
                data-placement={popperPlacement}
              />
            )}
          </div>
        </div>,
        portalTarget
      )}
    </>
  );
}
