import classNames from "classnames";
import { ReactNode } from "react";
import { Card } from "./Card";
import { CloseButton } from "./CloseButton";
import "./modalCard.scss";

export type ModalCardProps<TResult> = {
  close: (result?: TResult) => void;
  headerContent: ReactNode;
  bodyContent: ReactNode;
  className?: string;
};

export function ModalCard<TResult>({
  className,
  close,
  bodyContent,
  headerContent,
}: ModalCardProps<TResult>) {
  return (
    <Card className={classNames("modal-card", className)}>
      <div className="modal-card__header">
        <CloseButton
          className="modal-card__close-button"
          onClick={() => close()}
        />
        {headerContent}
      </div>
      <div className="modal-card__body">{bodyContent}</div>
    </Card>
  );
}
