import "./aboutYou.scss";
import {
  Form,
  InputText,
  InputMask,
  useFormConfig,
  InputButtonGroup,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { NavButton } from "@src/NavButton";
import { RecapCard } from "@src/RecapCard";
import { getYear } from "date-fns";
import { LoaderOverlay } from "@src/LoaderOverlay";
import {
  Button,
  Desktop,
  Emphasis,
  FormAction,
  FormField,
  FormGrid,
  FormNotice,
  FormRow,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { InfoBulle } from "@src/InfoBulle";
import { AutoCompleteCityDialog } from "@src/AutoCompleteCityDialog";
import axios from "axios";
import { displayToast } from "@src/Toaster";
import { captureException } from "@sentry/react";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { StepProps } from "./Steps";
import {
  CONSENTEMENT_AVERTISSEMENT,
  CONSENT_CONTACT_OFFFRE,
  CONSENT_DEMATERIALISE,
  SOUHAIT_ADHERER,
} from "./Consent";
import {
  LABEL_CERTIFY_CORRECT_AGE,
  LABEL_CERTIFY_CORRECT_VOLONTE,
  LABEL_CERTIFY_NO_DEMARCHE,
  LABEL_CERTIFY_NO_DEPENDANCE,
  LABEL_CERTIFY_NO_INVALIDITY,
} from "./Eligibilite";

enum SituationPro {
  ACTIF = "SALARIE:STATUT_PROFESSIONNEL_TYPE",
  HORS_ACTIVITE = "SANS_PROFESSION:STATUT_PROFESSIONNEL_TYPE",
}

export type AboutYouData = undefined;
type AboutYouProps = StepProps<AboutYouData>;

function cleanMaskedInput(val: unknown) {
  if (typeof val !== "string") return undefined;
  return val.replace(/\s/g, "");
}

const emptyStringToUndefined = zod.literal("").transform(() => undefined);

export function asOptionalField<T extends zod.ZodTypeAny>(schema: T) {
  return schema.optional().or(emptyStringToUndefined);
}

// regle validation telephone https://www.arcep.fr/la-regulation/grands-dossiers-thematiques-transverses/la-numerotation.html

const schema = zod.object({
  mail: zod.string().optional(),
  address: zod
    .string({ required_error: "Champ requis" })
    .min(1, "Champ requis"),
  postalCode: zod
    .string({ required_error: "Champ requis" })
    .min(1, "Champ requis")
    .regex(/^\d{5}/, { message: "Code invalide" }),
  city: zod.string({ required_error: "Champ requis" }).min(1, "Champ requis"),
  mobilePhone: zod.preprocess(
    cleanMaskedInput,
    zod
      .string({ required_error: "Champ requis" })
      .min(1, "Champ requis")
      .regex(/^06\d{8}|07[3-9]\d{7}$/, { message: "Numéro invalide" })
  ),
  phone: asOptionalField(
    zod.preprocess(
      cleanMaskedInput,
      zod.string().regex(/^0[1-5]\d{8}$/, { message: "Numéro invalide" })
    )
  ),
  situationPro: zod.nativeEnum(SituationPro, {
    required_error: "Champ requis",
  }),
});
type FormValues = zod.infer<typeof schema>;

export function AboutYou({
  changeStep,
  stepsData,
  projetPreSouscription: project,
  projectId,
}: AboutYouProps) {
  const formConfig = useFormConfig<typeof schema, FormValues>({
    schema,
    defaultValues: {
      mail: project?.contact.mail,
      mobilePhone: project?.contact.mobile,
    },
  });

  const postalCode = formConfig.watch("postalCode");

  if (!project || !projectId) return null;

  const handleSubmit = async (submittedData: FormValues) => {
    const consentements = [];

    if (stepsData.CONSENT?.souhaitAdherer) {
      consentements.push({
        description: SOUHAIT_ADHERER(),
        type: "SOUHAIT_ADHERER",
      });
    }

    if (stepsData.CONSENT?.consentDemat) {
      consentements.push({
        description: CONSENT_DEMATERIALISE,
        type: "CONSENTEMENT_DEMATERIALISATION",
      });
    }
    if (stepsData.CONSENT?.consentContactOffre) {
      consentements.push({
        description: CONSENT_CONTACT_OFFFRE(),
        type: "CONSENT_CONTACT_OFFRE",
      });
    }
    if (stepsData.CONSENT?.consentAvertissement) {
      consentements.push({
        type: "CONSENTEMENT_AVERTISSEMENT",
        description: CONSENTEMENT_AVERTISSEMENT,
      });
    }
    // consent eligibilite
    if (stepsData.ELIGIBILITE.certifyCorrectVolonte) {
      consentements.push({
        type: "CERTIFY_CORRECT_VOLONTE",
        description: `${LABEL_CERTIFY_CORRECT_VOLONTE} - Oui`,
      });
    }
    if (stepsData.ELIGIBILITE.certifyCorrectAge) {
      consentements.push({
        type: "CERTIFY_CORRECT_AGE",
        description: `${LABEL_CERTIFY_CORRECT_AGE} - Non`,
      });
    }
    if (stepsData.ELIGIBILITE.certifyNoInvalidity) {
      consentements.push({
        type: "CERTIFY_NO_INVALIDITY",
        description: `${LABEL_CERTIFY_NO_INVALIDITY} - Non`,
      });
    }
    if (stepsData.ELIGIBILITE.certifyNoDependance) {
      consentements.push({
        type: "CERTIFY_NO_DEPENDANCE",
        description: `${LABEL_CERTIFY_NO_DEPENDANCE} - Non`,
      });
    }
    if (stepsData.ELIGIBILITE.certifyNoDemarche) {
      consentements.push({
        type: "CERTIFY_NO_DEMARCHE",
        description: `${LABEL_CERTIFY_NO_DEMARCHE} - Non`,
      });
    }

    try {
      await axios.put<unknown>(
        `//${process.env.VITE_URL_GATEWAY}/devis/${projectId}`,
        {
          mailAssure: project.contact.mail,
          adresse: {
            voie: submittedData.address,
            codePostal: submittedData.postalCode,
            ville: submittedData.city,
          },
          assure: {
            telephone: cleanMaskedInput(submittedData.phone),
            mobile: cleanMaskedInput(submittedData.mobilePhone),
            situationPro: submittedData.situationPro,
          },
          consentements,
        }
      );
      changeStep("CHECK_YOUR_MAIL", "forward");
    } catch (err) {
      displayToast("Une erreur inconnue est survenue", "error");
      captureException(err);
      console.error(err);
      throw err;
    }
  };

  return (
    <>
      <LoaderOverlay
        isLoading={
          project === undefined ||
          formConfig.formState.isSubmitting ||
          formConfig.formState.isSubmitSuccessful
        }
      />
      {project !== undefined && (
        <div className="about-you-step">
          <div className="stepper__centered-container">
            <div className="stepper__centered-content step__grid step__grid--padding-bottom ">
              <NavButton
                onClick={() => {
                  changeStep("CONSENT", "backward");
                }}
              >
                Précédent
              </NavButton>
              <RecapCard
                className="about-you-step__recap-card"
                age={
                  getYear(new Date()) -
                  getYear(new Date(project.contact.birthDate))
                }
                couvertureName={project.tarif.name}
                simulationCotisationMonth={project.tarif.simulationCotisation}
              />
              <Form
                className="about-you-step__form"
                formConfig={formConfig}
                onSubmit={handleSubmit}
              >
                <FormGrid>
                  <FormTitle>
                    <h1>
                      {project.contact.civility} {project.contact.surname}{" "}
                      {project.contact.name},{" "}
                      <Emphasis>parlez-nous de vous</Emphasis>
                    </h1>
                  </FormTitle>
                  <FormNotice>
                    <Emphasis>*</Emphasis> Champs obligatoires.
                  </FormNotice>
                  <FormRow>
                    <FormField className="about-you-step__mail-info-bulle-wrapper">
                      <InputText<FormValues>
                        name="mail"
                        label="Adresse mail"
                        disabled
                      />
                      <Desktop>
                        <InfoBulle className="about-you-step__mail-info-bulle">
                          <div
                            className="info-bulle__arrow about-you-step__mail-info-bulle-arrow"
                            data-placement="left"
                          />
                          <p>Vous ne pouvez pas modifier le mail communiqué</p>
                        </InfoBulle>
                      </Desktop>
                    </FormField>
                  </FormRow>
                  <FormRow>
                    <FormField>
                      <InputText<FormValues>
                        name="address"
                        label="Adresse postale"
                        required
                      />
                    </FormField>
                  </FormRow>
                  <FormRow>
                    <FormField
                      size="half"
                      className="about-you-step__autocomplete-field"
                    >
                      <InputMask<FormValues>
                        mask="99999"
                        name="postalCode"
                        label="Code postal"
                        required
                      />
                      <AutoCompleteCityDialog
                        className="about-you-step__autocomplete-card"
                        postalCode={postalCode}
                        onSelect={(city) => {
                          formConfig.setValue("city", city, {
                            shouldValidate: true,
                            shouldTouch: true,
                          });
                          const node =
                            document.querySelector<HTMLInputElement>(
                              'input[name="city"]'
                            );
                          if (node) node.focus();
                        }}
                      />
                    </FormField>
                    <FormField size="half">
                      <InputText<FormValues>
                        name="city"
                        label="Ville"
                        required
                      />
                    </FormField>
                  </FormRow>
                  <FormRow>
                    <FormField size="half">
                      <InputMask<FormValues>
                        mask="99 99 99 99 99"
                        name="mobilePhone"
                        label="Téléphone mobile"
                        required
                      />
                    </FormField>
                    <FormField size="half">
                      <InputMask<FormValues>
                        name="phone"
                        label="Téléphone fixe"
                        mask="99 99 99 99 99"
                      />
                    </FormField>
                  </FormRow>
                  <FormRow>
                    <FormField>
                      <div className="about-you-step__input-button-group">
                        <InputButtonGroup<FormValues, SituationPro>
                          label="Votre situation professionnelle"
                          name="situationPro"
                          options={[
                            { label: "Actif", value: SituationPro.ACTIF },
                            {
                              label: "Hors activité",
                              value: SituationPro.HORS_ACTIVITE,
                            },
                          ]}
                        />
                      </div>
                    </FormField>
                  </FormRow>
                  <FormAction>
                    <Button
                      type="submit"
                      disabled={
                        formConfig.formState.isSubmitting ||
                        formConfig.formState.isSubmitSuccessful
                      }
                    >
                      Poursuivre ma demande d&apos;adhésion
                    </Button>
                  </FormAction>
                </FormGrid>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
