import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as CheckCircle } from "@src/svg/check-circle.svg";
import { environment } from "@src/MarqueBlanche";
import { StepProps } from "./Steps";
import "./confirmSimulation.scss";

export type ConfirmSimulationData = undefined;
type ConfirmSimulationProps = StepProps<ConfirmSimulationData>;

export function ConfirmSimulation({ changeStep }: ConfirmSimulationProps) {
  return (
    <div className="confirm-simulation-step">
      <div className="stepper__centered-container">
        <div className="stepper__centered-content step__grid confirm-simulation-step__main">
          <CheckCircle className="confirm-simulation-step__svg" />
          <h1>Votre simulation vous a été envoyée par mail</h1>
          <p>Vérifiez votre boîte de courriers indésirables.</p>
          <Button onClick={() => changeStep("ELIGIBILITE", "forward")}>
            Souscrire en ligne
          </Button>
          <Button
            variant="outline-accent"
            onClick={() =>
              window.location.replace(environment.current.maisonMereUrl)
            }
          >
            Retour au site
          </Button>
        </div>
      </div>
    </div>
  );
}
