import { Emphasis } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ModalContentProps } from "./Modal";
import { ModalCard } from "./ModalCard";
import "./garantieModal.scss";

export function GarantieAssistanceModal({ close }: ModalContentProps) {
  return (
    <ModalCard
      close={close}
      className="garantie-modal"
      headerContent={
        <div className="garantie-modal__header">
          <h1>
            <Emphasis>Détails des garanties assistance ⁽¹⁾</Emphasis>
          </h1>
          <p>
            <b>
              Ces garanties sont assurées et réalisées par Ressources Mutuelles
              Assistance ⁽²⁾.
            </b>
          </p>
        </div>
      }
      bodyContent={
        <div className="garantie-modal__body">
          <div className="garantie-modal__item">
            <h2>
              Pour accompagner le membre participant en cas de dépendance :
            </h2>
          </div>
          <div className="garantie-modal__item">
            <p className="garantie-modal__item-label">
              Service d&apos;écoute, conseil et orientation :
            </p>
            <p>
              Sur les dispositifs de services à la personne, les aides légales
              et extra-légales, conseils et informations médicales et service
              d&apos;accompagnement à la parentalité.
            </p>
          </div>
          <div className="garantie-modal__item">
            <p className="garantie-modal__item-label">
              Accompagnement psycho-social :
            </p>
            <p>
              Service d&apos;appels de convivialité, service de conseil social,
              service de soutien psychologique.
            </p>
          </div>
          <div className="garantie-modal__item">
            <p className="garantie-modal__item-label">
              Prestations d&apos;assistance en cas de dépendance :
            </p>
            <p>
              Aide à domicile, prestations de bien-être et de soutien, garde
              d&apos;enfants ou de personnes dépendantes, garde des animaux
              familiers, téléassistance.
            </p>
          </div>
          <div className="garantie-modal__item">
            <h2>
              Pour accompagner un de vos proches aidant familial ou un proche
              aidant :
            </h2>
          </div>
          <div className="garantie-modal__item">
            <p className="garantie-modal__item-label">
              Prestations d&apos;assistance complémentaires et spécifiques aux
              aidants :
            </p>
            <p>
              Bilan psycho-social de la situation, assistance préventive,
              assistance en cas de situation de crise.
            </p>
          </div>
          <div className="garantie-modal__item">
            <p className="garantie-modal__item-label">
              Prestations d&apos;assistance en cas de décès :
            </p>
            <p>Accompagnement spécifique en cas de décès et d&apos;écoute.</p>
          </div>
          <div className="garantie-modal__notice">
            <p>
              <sup>(1)</sup> Se référer à l&apos;annexe 7, Garantie assistance
              du{" "}
              <a
                tabIndex={-1}
                target="_blank"
                href="https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/REGLEMENT_tutelaire.pdf"
                rel="noreferrer"
              >
                Règlement du contrat sâge autonomie.
              </a>
            </p>
            <p>
              <sup>(2)</sup> Les garanties d&apos;assistance sont assurées et
              réalisées par Ressources Mutuelles Assistance, union
              d&apos;assistance soumise aux dispositions du Livre II du Code de
              la mutualité, dont le siège social est situé 46 rue du Moulin - CS
              32427 - 44124 Vertou Cedex et immatriculée sous le numéro Siren
              444 269 682.
            </p>
          </div>
        </div>
      }
    />
  );
}
