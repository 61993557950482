import { ReactComponent as CheckCircle } from "@src/svg/check-circle.svg";
import "./success.scss";

export type SuccessData = undefined;

export function Success() {
  return (
    <div className="check-your-mail-step">
      <div className="stepper__centered-container">
        <div className="stepper__centered-content step__grid check-your-mail-step__main">
          <CheckCircle className="success-step__svg" />
          <h1>Votre souscription est terminée et nous vous en remercions</h1>
        </div>
      </div>
    </div>
  );
}
