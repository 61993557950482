import {
  Button,
  Desktop,
  Emphasis,
  Mobile,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import fr from "date-fns/locale/fr"; // eslint-disable-line import/no-duplicates
import { format, getYear } from "date-fns"; // eslint-disable-line import/no-duplicates
import { formatCurrency } from "@src/utils";
import { Collapse } from "react-collapse";
import { useEffect, useState } from "react";
import "./recapSignature.scss";
import axios from "axios";
import { displayToast } from "@src/Toaster";
import { LoaderAnimation } from "@src/LoaderAnimation";
import classNames from "classnames";
import { captureException } from "@sentry/react";
import { InfoBloc } from "@src/InfoBloc";
import { StepProps } from "./Steps";

export type RecapSignatureData = undefined;
type RecapSignatureProps = StepProps<RecapSignatureData>;

export function RecapSignature({
  projetSignature,
  projectId,
  stepsData,
}: RecapSignatureProps) {
  const [compteur, setCompteur] = useState<number | undefined>(undefined);
  const [isWaitingForProcedure, setWaitingForProcedure] = useState(true);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [signButtonIsLoading, setSignButtonIsLoading] = useState(false);

  useEffect(() => {
    if (
      (!stepsData.PIECES_JUSTIFICATIVES.hasSubmitted ||
        stepsData.PIECES_JUSTIFICATIVES.hasResolved) &&
      compteur === undefined
    ) {
      setTimeout(() => {
        setWaitingForProcedure(false);
      }, 61_000);
      setInterval(() => {
        setCompteur((c) => (c === undefined ? 60 : c - 1));
      }, 1_000);
    }
  }, [
    stepsData.PIECES_JUSTIFICATIVES.hasSubmitted,
    stepsData.PIECES_JUSTIFICATIVES.hasResolved,
    compteur,
  ]);

  if (!projetSignature || !projectId) return null;
  const age = getYear(new Date()) - getYear(projetSignature.assure.birthDate);

  const handleClickSign = () => {
    setSignButtonIsLoading(true);
    if (projetSignature.urlSignature) {
      window.location.replace(projetSignature.urlSignature);
    } else {
      axios
        .get<{ urlSignature?: string }>(
          `//${process.env.VITE_URL_GATEWAY}/projet/${projectId}/souscription`
        )
        .then((res) => {
          if (!res.data.urlSignature) {
            setSignButtonIsLoading(false);
            displayToast(
              "Votre lien de signature n'est pas encore pret. Veuillez patienter quelques instants puis reéssayer",
              "success"
            );
          } else {
            window.location.replace(res.data.urlSignature);
          }
        })
        .catch((err) => {
          captureException(err);
          console.error(err);
          setSignButtonIsLoading(false);
          displayToast("Une erreur est survenue", "error");
        });
    }
  };

  return (
    <div className="recap-signature-step">
      <div className="stepper__centered-container ">
        <div className="stepper__centered-content step__grid recap-signature-step__main">
          <h1>
            Il ne vous reste <Emphasis>plus qu&apos;à signer !</Emphasis>
          </h1>
          <div className="recap-signature-step__progress step__grid-bloc">
            <div className="recap-signature-step__progress-item">
              <div className="recap-signature-step__progress-top">
                <span>01</span>
              </div>
              <p className="recap-signature-step__progress-name">
                Au clic sur le bouton « Aller à la signature en ligne », vous
                serez redirigé vers le site sécurisé de notre partenaire
                Yousign.
              </p>
            </div>
            <div className="recap-signature-step__progress-item">
              <div className="recap-signature-step__progress-top">
                <span>02</span>
              </div>
              <p className="recap-signature-step__progress-name">
                Une fois tous les documents signés, vous recevrez un mail
                confirmant que nous avons bien reçu vos documents et que votre
                dossier est en cours de traitement.
              </p>
            </div>
          </div>
          <div className="recap-signature-step__card step__grid-bloc">
            <div className="recap-signature-step__collapse-button-wrapper">
              <button
                data-isopen={collapseIsOpen}
                className="recap-signature-step__collapse-button"
                type="button"
                onClick={() => {
                  setCollapseIsOpen(!collapseIsOpen);
                }}
              >
                {collapseIsOpen
                  ? "Masquer le récapitulatif"
                  : "Voir le récapitulatif"}
              </button>
            </div>
            <div className="recap-signature-step__card-part">
              <h2>Votre souscription</h2>
              <p className="recap-signature-step__soucription-recap">
                Pour une souscription à l&apos;âge de{" "}
                <Emphasis>{`${age} ans`}</Emphasis>,
                <Desktop>
                  <br />
                </Desktop>
                <Mobile>
                  <span> </span>
                </Mobile>
                et pour un{" "}
                <Emphasis>
                  {`${projetSignature.devis.formule.toLowerCase()} de couverture`}
                </Emphasis>{" "}
                :
              </p>
              <p className="recap-signature-step__soucription-recap">
                Votre cotisation s&apos;élèvera à{" "}
                <Emphasis>{`${formatCurrency(
                  projetSignature.devis.montantMensuel
                )} TTC par mois`}</Emphasis>
                ,
                <Desktop>
                  <br />
                </Desktop>
                <Mobile>
                  <span> </span>
                </Mobile>
                soit{" "}
                <Emphasis>{`${formatCurrency(
                  projetSignature.devis.montantMensuel * 12
                )} TTC par an.`}</Emphasis>
              </p>
            </div>
            <Collapse
              isOpened={collapseIsOpen}
              theme={{
                collapse: "recap-signature-step__collapse",
                content: "recap-signature-step__collapse-content",
              }}
            >
              <hr />
              <div className="recap-signature-step__card-part">
                <h2>Vos informations</h2>
                <div className="recap-signature-step__info-grid">
                  <div className="recap-signature-step__info-item">
                    <p>Identité</p>
                    <p>
                      {projetSignature.assure.civility}{" "}
                      {projetSignature.assure.surname}{" "}
                      {projetSignature.assure.name}
                    </p>
                  </div>
                  <div className="recap-signature-step__info-item">
                    <p>Adresse mail</p>
                    <p>{projetSignature.assure.mail}</p>
                  </div>
                  <div className="recap-signature-step__info-item">
                    <p>Téléphone mobile</p>
                    <p>{projetSignature.assure.mobile}</p>
                  </div>
                  <div className="recap-signature-step__info-item">
                    <p>Adresse postale</p>
                    <p>
                      {projetSignature.assure.address}
                      <br />
                      {projetSignature.assure.postalCode}{" "}
                      {projetSignature.assure.city}
                    </p>
                  </div>
                  {projetSignature.assure.telephone && (
                    <div className="recap-signature-step__info-item">
                      <p>Téléphone fixe</p>
                      <p>{projetSignature.assure.telephone}</p>
                    </div>
                  )}
                  <div className="recap-signature-step__info-item">
                    <p>Date de naissance</p>
                    <p>
                      {format(projetSignature.assure.birthDate, "PPP", {
                        locale: fr,
                      })}
                    </p>
                  </div>
                  <div className="recap-signature-step__info-item">
                    <p>Numéro de sécurité sociale</p>
                    <p>{projetSignature.assure.numeroSecu}</p>
                  </div>
                </div>
              </div>
              <div className="recap-signature-step__bank-card">
                <h2>Coordonnées bancaires</h2>
                <div className="recap-signature-step__info-list">
                  <div className="recap-signature-step__info-item">
                    <p>Titulaire</p>
                    <p>{projetSignature.rib.beneficiaire}</p>
                  </div>
                  <div className="recap-signature-step__info-item">
                    <p>IBAN</p>
                    <p>{projetSignature.rib.iban}</p>
                  </div>
                  <div className="recap-signature-step__info-item">
                    <p>BIC</p>
                    <p>{projetSignature.rib.bic}</p>
                  </div>
                </div>
              </div>
              <div className="recap-signature-step__card-part">
                <h2>Pièces justificatives</h2>
                <div className="recap-signature-step__doc-list">
                  {projetSignature.piecesJustificatives.map((pj) => (
                    <a
                      key={pj.document?.url}
                      className="recap-signature-step__doc-link"
                      href={pj.document?.url}
                    >
                      {pj.document?.fileUploadedName}
                    </a>
                  ))}
                </div>
              </div>
            </Collapse>
          </div>
          {compteur !== undefined && isWaitingForProcedure ? (
            <InfoBloc variant="time">
              <p>
                Merci de patienter encore quelques instants ({compteur || 0}{" "}
                secondes)
              </p>
            </InfoBloc>
          ) : null}
          <div className="step__grid-bloc">
            <div className="recap-signature-step__submit-wrapper">
              <Button
                className={classNames(
                  "recap-signature-step__submit",
                  isWaitingForProcedure && "recap-signature-step__submit-grey"
                )}
                type="button"
                disabled={signButtonIsLoading || isWaitingForProcedure}
                onClick={handleClickSign}
              >
                <div
                  data-loading-left={isWaitingForProcedure}
                  data-loading={signButtonIsLoading}
                  className="recap-signature-step__submit-content"
                >
                  {isWaitingForProcedure && <LoaderAnimation />}
                  <p>Aller à la signature en ligne</p>
                  {signButtonIsLoading && <LoaderAnimation />}
                </div>
              </Button>
              {isWaitingForProcedure && (
                <p>
                  Veuillez patienter quelques instants, Votre procédure de
                  signature est en cours de création
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
