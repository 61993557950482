import "./recapSouscription.scss";
import {
  Button,
  Emphasis,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { Card } from "@src/Card";
import { LoaderOverlay } from "@src/LoaderOverlay";
import fr from "date-fns/locale/fr"; // eslint-disable-line import/no-duplicates
import { format } from "date-fns"; // eslint-disable-line import/no-duplicates
import { StepProps } from "./Steps";

export type RecapSouscriptionData = undefined;
type RecapSouscriptionProps = StepProps<RecapSouscriptionData>;

export function RecapSouscription({
  changeStep,
  projetSouscription,
}: RecapSouscriptionProps) {
  return (
    <>
      <LoaderOverlay isLoading={projetSouscription === undefined} />
      {projetSouscription !== undefined && (
        <div className="recap-souscription-step">
          <div className="stepper__centered-container ">
            <div className="recap-souscription-step__main stepper__centered-content step__grid step__grid--padding-bottom">
              <h1>
                Votre souscription <Emphasis>en quelques clics !</Emphasis>
              </h1>
              <h3>Merci de vérifier que vos informations sont exactes :</h3>
              <div className="step__grid-bloc step__grid-bloc--small-margin-top">
                <Card className="recap-souscription-step__card">
                  <h2>
                    <span className="recap-souscription-step__civility">
                      {projetSouscription.assure.civility}
                    </span>{" "}
                    {projetSouscription.assure.surname}{" "}
                    {projetSouscription.assure.name}
                  </h2>
                  <div className="recap-souscription-step__info-grid">
                    <div className="recap-souscription-step__info-item">
                      <p>Adresse mail</p>
                      <p>{projetSouscription.assure.mail}</p>
                    </div>
                    <div className="recap-souscription-step__info-item">
                      <p>Téléphone mobile</p>
                      <p>{projetSouscription.assure.mobile}</p>
                    </div>
                    <div className="recap-souscription-step__info-item">
                      <p>Adresse postale</p>
                      <p>
                        {projetSouscription.assure.address}
                        <br />
                        {projetSouscription.assure.postalCode}{" "}
                        {projetSouscription.assure.city}
                      </p>
                    </div>
                    {projetSouscription.assure.telephone && (
                      <div className="recap-souscription-step__info-item">
                        <p>Téléphone fixe</p>
                        <p>{projetSouscription.assure.telephone}</p>
                      </div>
                    )}
                    <div className="recap-souscription-step__info-item">
                      <p>Date de naissance</p>
                      <p>
                        {format(projetSouscription.assure.birthDate, "PPP", {
                          locale: fr,
                        })}
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
              <Button onClick={() => changeStep("NUM_SECU", "forward")}>
                Suivant
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
