import { ReactComponent as Envelope } from "@src/svg/envelope.svg";
import "./checkYourMail.scss";

export type CheckYourMailData = undefined;

export function CheckYourMail() {
  return (
    <div className="check-your-mail-step">
      <div className="stepper__centered-container">
        <div className="stepper__centered-content step__grid check-your-mail-step__main">
          <Envelope className="check-your-mail-step__svg" />
          <h1>Nous vous avons envoyé un lien par mail</h1>
          <p>
            Ce lien vous donne accès à la suite de votre parcours
            d&apos;adhésion.
            <br />
            N&apos;hésitez pas à regarder dans vos courriers indésirables.
          </p>
        </div>
      </div>
    </div>
  );
}
