type FooterMSPStepDefaultProps = {
  footnoteReference?: boolean;
};

export function FooterMSPStepDefault({
  footnoteReference,
}: FooterMSPStepDefaultProps) {
  return (
    <p id="footer-legal__footnote">
      <small>
        {footnoteReference && "(1) "}Le contrat sâge autonomie vous est proposé
        par la Mutuelle des Services Publics, mutuelle soumise aux dispositions
        du livre II du Code de la mutualité, dont le siège social est situé 1
        rue François Moisson 13002 Marseille et immatriculée sous le numéro
        Siren 782 825 368 substituée auprès de Solimut Mutuelle de France,
        mutuelle soumise aux dispositions du Livre II du Code de la mutualité n°
        SIREN 383 143 617et au contrôle de l&apos;Autorité de Contrôle
        Prudentiel et de Résolution (A.C.P.R. : 4 place de Budapest – CS 92459 –
        75 436 Paris Cedex 9). Elle agit en qualité de distributeur de
        Tutélaire.
      </small>
      <small>
        Mutuelle soumise aux dispositions du livre II du Code de la mutualité,
        dont le siège social est situé 157 avenue de France – 75013 Paris et
        immatriculée sous le numéro Siren 775 682 164.
      </small>
      <small>
        Les garanties dépendance sont assurées et réalisées par la mutuelle
        Tutélaire. Les garanties service et protection juridiques sont assurées
        et réalisées par Solucia Service et Protection Juridiques, société
        anonyme à directoire et conseil de surveillance au capital social de 9
        600 000 € régie par le Code des assurances, dont le siège social est
        situé 111 avenue de France – CS 51519 – 75634 Paris cedex 13 et
        immatriculée au RCS de Paris sous le numéro Siren 481 997 708.
      </small>
      <small>
        Les garanties d&apos;assistance sont assurées et réalisées par
        Ressources Mutuelles Assistances, union d&apos;assistance soumise aux
        dispositions du Livre II du Code de la mutualité, dont le siège social
        est situé 46 rue du Moulin - CS 32427 - 44124 Vertou Cedex et
        immatriculée sous le numéro Siren 444 269 682.
      </small>
    </p>
  );
}
