import classNames from "classnames";
import { ReactNode } from "react";
import { CloseButton } from "./CloseButton";
import "./infoBulle.scss";

type InfoBulleProps = {
  children: ReactNode;
  className?: string;
  onCancel?: () => void;
};
export function InfoBulle({ children, className, onCancel }: InfoBulleProps) {
  return (
    <div className={classNames("info-bulle", className)}>
      <div className="info-bulle__inner">
        {!!onCancel && (
          <CloseButton
            className="info-bulle__close-button"
            onClick={onCancel}
          />
        )}
        {children}
      </div>
    </div>
  );
}
