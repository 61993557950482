import { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./stepper.scss";
import { StepLoader } from "./StepLoader";
import { TopBar } from "./Topbar";
import { ProgressBar } from "./ProgressBar";
import { SidePane } from "./SidePane";
import { StepName, StepProps, STEPS, StepsData } from "./Steps/Steps";
import {
  ProjetPreSouscription,
  ProjetSignature,
  ProjetSouscription,
} from "./App";
import { FooterLegal } from "./FooterLegal";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
type StepperProps = {
  projetSouscription?: ProjetSouscription;
  projetSignature?: ProjetSignature;
  projetPreSouscription?: ProjetPreSouscription;
  setProjetPreSouscription: Dispatch<
    SetStateAction<ProjetPreSouscription | undefined>
  >;
  setProjetSignature: Dispatch<SetStateAction<ProjetSignature | undefined>>;
  hasFinished: boolean;
  projectId?: string;
};

type Transition = {
  direction: "forward" | "backward";
  nextStep: StepName;
};

type StepperState = {
  activeStep: StepName;
  transition?: Transition;
};

function getInitialStep(
  hasUrlSignature: boolean,
  hasProjetPreSouscription: boolean,
  hasProjetSouscription: boolean,
  hasProjetSignature: boolean,
  hasFinished: boolean
): StepName {
  if (hasFinished) return "SUCCESS";
  if (hasUrlSignature) return "RECAP_SIGNATURE";
  if (hasProjetSignature) return "PIECES_JUSTIFICATIVES";
  if (hasProjetSouscription) return "RECAP_SOUSCRIPTION";
  if (hasProjetPreSouscription) return "ELIGIBILITE";
  return "LANDING";
}

const TRANSITION_DURATION = 200;
function goToStep(
  state: StepperState,
  setState: Dispatch<SetStateAction<StepperState>>,
  nextStep: StepName,
  direction: "forward" | "backward"
) {
  if (state.transition) return;
  setState({
    ...state,
    transition: {
      direction,
      nextStep,
    },
  });
  setTimeout(() => {
    setState({
      activeStep: nextStep,
    });
  }, TRANSITION_DURATION);
}

export function Stepper({
  projetPreSouscription,
  projetSouscription,
  projetSignature,
  setProjetSignature,
  setProjetPreSouscription,
  hasFinished,
  projectId,
}: StepperProps) {
  const [state, setState] = useState<StepperState>({
    activeStep: getInitialStep(
      !!projetSignature?.urlSignature,
      !!projetPreSouscription,
      !!projetSouscription,
      !!projetSignature,
      hasFinished
    ),
  });
  const [stepsData, setStepData] = useState<StepsData>({
    LANDING: undefined,
    SITUATION: undefined,
    BIRTHDATE: {},
    RECAP: undefined,
    TARIF: undefined,
    CONTACT_INFOS: {},
    CONFIRM_SIMULATION: undefined,
    CONSENT: undefined,
    ABOUT_YOU: undefined,
    CHECK_YOUR_MAIL: undefined,
    RECAP_SOUSCRIPTION: undefined,
    NUM_SECU: undefined,
    CONSENT_SOUSCRIPTION: undefined,
    MANDAT_PRELEVEMENT: undefined,
    PIECES_JUSTIFICATIVES: {},
    RECAP_SIGNATURE: undefined,
    SUCCESS: undefined,
    ELIGIBILITE: {},
  });

  useEffect(() => {
    window.dataLayer?.push({
      activeStep: state.activeStep,
      event: "TPV",
    });
  }, [state.activeStep]);

  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);

  const LoadedComp = STEPS[state.activeStep].component as (
    props: StepProps<unknown>
  ) => JSX.Element;

  return (
    <>
      <TopBar activeMacroStep={STEPS[state.activeStep].macroStep} />
      <div className="stepper">
        <div className="stepper__progress-bar-container stepper__centered-container">
          <div className="stepper__centered-content">
            <ProgressBar
              hidden={false}
              progress={
                STEPS[state.transition?.nextStep || state.activeStep].progress
              }
            />
          </div>
        </div>
        <div className="stepper__step-container">
          <fieldset
            className="stepper__step stepper__fieldset"
            data-transition={state.transition?.direction}
            data-hidden={false}
            disabled={isSidePaneOpen}
          >
            <StepLoader key={state.activeStep}>
              <LoadedComp
                projectId={projectId}
                projetPreSouscription={projetPreSouscription}
                projetSouscription={projetSouscription}
                projetSignature={projetSignature}
                setProjetPreSouscription={setProjetPreSouscription}
                setProjetSignature={setProjetSignature}
                data={stepsData[state.activeStep]}
                stepsData={stepsData}
                setData={(data: unknown) => {
                  setStepData(
                    (prevState) =>
                      ({
                        ...prevState,
                        [state.activeStep]: data,
                      } as unknown as StepsData)
                  );
                }}
                changeStep={(
                  stepName: StepName,
                  transitionDirection: "forward" | "backward"
                ) => {
                  setTimeout(() => {
                    window.scrollTo({ top: 0 });
                  }, 200);
                  goToStep(state, setState, stepName, transitionDirection);
                }}
              />

              <FooterLegal stepName={state.activeStep} />
            </StepLoader>
          </fieldset>
        </div>
      </div>
      <SidePane
        isOpen={isSidePaneOpen}
        setIsOpen={setIsSidePaneOpen}
        activeStep={state.transition?.nextStep || state.activeStep}
        hidden={false}
      />
    </>
  );
}
