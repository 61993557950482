import { FooterMSPStepAboutYou } from "@src/Footer/MSP/FooterMSPStepAboutYou";
import { FooterMSPStepBirthDate } from "@src/Footer/MSP/FooterMSPStepBirthDate";
import { FooterMSPStepContactInfos } from "@src/Footer/MSP/FooterMSPStepContactInfos";
import { FooterMSPStepDefault } from "@src/Footer/MSP/FooterMSPStepDefault";
import { FooterMSPStepEligibilite } from "@src/Footer/MSP/FooterMSPStepEligibilite";
import { FooterMSPStepMandatPrelevement } from "@src/Footer/MSP/FooterMSPStepMandatPrelevement";
import { FooterMSPStepNumSecu } from "@src/Footer/MSP/FooterMSPStepNumSecu";
import { FooterMSPStepPiecesJustificatives } from "@src/Footer/MSP/FooterMSPStepPiecesJustificatives";
import { StepName } from "@src/Steps/Steps";
import React from "react";

type FooterByStepNameProps = {
  stepName: StepName;
};

export function FooterMSPByStepName({ stepName }: FooterByStepNameProps) {
  switch (stepName) {
    case "BIRTHDATE":
      return <FooterMSPStepBirthDate />;
    case "CONTACT_INFOS":
      return <FooterMSPStepContactInfos />;
    case "ELIGIBILITE":
      return <FooterMSPStepEligibilite />;
    case "ABOUT_YOU":
      return <FooterMSPStepAboutYou />;
    case "NUM_SECU":
      return <FooterMSPStepNumSecu />;
    case "MANDAT_PRELEVEMENT":
      return <FooterMSPStepMandatPrelevement />;
    case "PIECES_JUSTIFICATIVES":
      return <FooterMSPStepPiecesJustificatives />;
    case "CONSENT":
      return <FooterMSPStepDefault footnoteReference />;
    default:
      return <FooterMSPStepDefault />;
  }
}
