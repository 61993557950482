import {
  Button,
  Emphasis,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import "./landing.scss";
import { PopperButton } from "@src/PopperButton";
import { InfoBulleButton } from "@src/InfoBulleButton";
import { InfoBulle } from "@src/InfoBulle";
import { ReactComponent as CheckCircle } from "@src/svg/check-circle.svg";
import { environment } from "@src/MarqueBlanche";
import { StepProps } from "./Steps";

export type LandingData = undefined;
type LandingProps = StepProps<LandingData>;

export function Landing({ changeStep }: LandingProps) {
  return (
    <div className="landing-step">
      <div className="stepper__centered-container landing-step__first-part">
        <div className="stepper__centered-content step__grid ">
          <h1>
            {"Le contrat sâge autonomie vous couvre et "}
            <Emphasis>
              vous accompagne en cas de perte d&apos;autonomie
            </Emphasis>
          </h1>
          <ul className="step__grid-bloc step__grid-bloc--large-space landing-step__check-list">
            <li>
              <CheckCircle className="landing-step__check-list-svg" />
              <p>
                Percevez un capital forfaitaire (versé une seule fois sur la
                durée du contrat) de 1 500 à 4 500€ selon le niveau souscrit
                pour, par exemple, vous aider à aménager votre logement et vous
                aider à rester à votre domicile et prévenir des risques, même en
                cas de dépendance partielle, dès votre rattachement au{" "}
                <PopperButton
                  popperPlacement="top-end"
                  openOnHover
                  withArrow
                  renderButton={(props) => (
                    <InfoBulleButton {...props}>GIR 4</InfoBulleButton>
                  )}
                  renderPopperContent={({ setVisible }) => (
                    <InfoBulle
                      className="landing-step__infobulle"
                      onCancel={() => setVisible(false)}
                    >
                      Degré de perte d&apos;autonomie évalué dans le cadre
                      d&apos;une demande d&apos;allocation personnalisée
                      d&apos;autonomie (APA).
                      <br />
                      Les GIR 1 et 2 correspondent à une dépendance lourde, les
                      GIR 3 et 4 à une dépendance partielle.
                      <br />
                      <p className="landing-step__infobulle-details">
                        Les Groupes Iso-Ressources (GIR) correspondent au niveau
                        de perte d’autonomie (état de dépendance) d’une
                        personne.
                        <br />
                        Ils sont calculés à partir de la grille AGGIR
                        (d’autonomie gérontologie groupes iso-ressources).
                        <br />
                        Les personnes évaluées en (GIR) 1 à 4 peuvent bénéficier
                        de l'APA (Allocation Personnalisée d’Autonomie).
                        <br />
                        Les (GIR) 1 et 2 correspondent à une dépendance lourde
                        et les (GIR) 3 et 4 à une dépendance partielle.
                        <br />
                        Une évaluation de la perte d’autonomie correspondant à
                        un (GIR) 5 ou 6 ne permet pas de bénéficier de l’APA
                        (Allocation Personnalisée d’Autonomie).
                      </p>
                    </InfoBulle>
                  )}
                />
              </p>
            </li>
            <li>
              <CheckCircle className="landing-step__check-list-svg" />
              Percevez une rente mensuelle de 150 à 450 € selon le niveau
              souscrit, dès votre rattachement au{" "}
              <PopperButton
                popperPlacement="top-end"
                openOnHover
                withArrow
                renderButton={(props) => (
                  <InfoBulleButton {...props}>GIR 1, 2 ou 3</InfoBulleButton>
                )}
                renderPopperContent={({ setVisible }) => (
                  <InfoBulle
                    className="landing-step__infobulle"
                    onCancel={() => setVisible(false)}
                  >
                    Degré de perte d&apos;autonomie évalué dans le cadre
                    d&apos;une demande d&apos;allocation personnalisée
                    d&apos;autonomie (APA).
                    <br />
                    Les GIR 1 et 2 correspondent à une dépendance lourde, les
                    GIR 3 et 4 à une dépendance partielle.
                    <br />
                    <p className="landing-step__infobulle-details">
                      Les Groupes Iso-Ressources (GIR) correspondent au niveau
                      de perte d’autonomie (état de dépendance) d’une personne.
                      <br />
                      Ils sont calculés à partir de la grille AGGIR (d’autonomie
                      gérontologie groupes iso-ressources).
                      <br />
                      Les personnes évaluées en (GIR) 1 à 4 peuvent bénéficier
                      de l'APA (Allocation Personnalisée d’Autonomie).
                      <br />
                      Les (GIR) 1 et 2 correspondent à une dépendance lourde et
                      les (GIR) 3 et 4 à une dépendance partielle.
                      <br />
                      Une évaluation de la perte d’autonomie correspondant à un
                      (GIR) 5 ou 6 ne permet pas de bénéficier de l’APA
                      (Allocation Personnalisée d’Autonomie).
                    </p>
                  </InfoBulle>
                )}
              />
            </li>
            <li>
              <CheckCircle className="landing-step__check-list-svg" />
              Bénéficiez de la garantie service et protection juridiques assurée
              et mise en œuvre par{" "}
              <PopperButton
                popperPlacement="top-start"
                openOnHover
                withArrow
                renderButton={(props) => (
                  <InfoBulleButton {...props}>
                    Solucia Service et Protection Juridiques
                  </InfoBulleButton>
                )}
                renderPopperContent={({ setVisible }) => (
                  <InfoBulle
                    className="landing-step__infobulle"
                    onCancel={() => setVisible(false)}
                  >
                    Les garanties service et protection juridiques sont assurées
                    et réalisées par Solucia Service et Protection Juridiques,
                    société anonyme à directoire et conseil de surveillance au
                    capital social de 9 600 000 € régie par le Code des
                    assurances, dont le siège social est situé 111 avenue de
                    France - CS 51519 - 75634 Paris cedex 13 et immatriculée au
                    RCS de Paris sous le numéro Siren 481 997 708.
                  </InfoBulle>
                )}
              />
              &nbsp; que vous soyez aidant ou aidé
            </li>
            <li>
              <CheckCircle className="landing-step__check-list-svg" />
              Profitez d&apos;une garantie assistance assurée et mise en œuvre
              par{" "}
              <PopperButton
                popperPlacement="top-start"
                openOnHover
                withArrow
                renderButton={(props) => (
                  <InfoBulleButton {...props}>
                    Ressources Mutuelles Assistance
                  </InfoBulleButton>
                )}
                renderPopperContent={({ setVisible }) => (
                  <InfoBulle
                    className="landing-step__infobulle"
                    onCancel={() => setVisible(false)}
                  >
                    Les garanties d&apos;assistance sont assurées et réalisées
                    par Ressources Mutuelles Assistance, union d&apos;assistance
                    soumise aux dispositions du Livre II du Code de la
                    mutualité, dont le siège social est situé 46 rue du Moulin -
                    CS 32427 - 44124 Vertou Cedex et immatriculée sous le numéro
                    Siren 444 269 682
                  </InfoBulle>
                )}
              />
              &nbsp; pour vous aider à faire face aux situations de perte
              d&apos;autonomie que vous soyez aidant ou aidé
            </li>
          </ul>
          <div className="step__grid-bloc">
            <h2>Laissez-vous guider !</h2>
            <div className="landing-step__progress">
              <div className="landing-step__progress-item">
                <div className="landing-step__progress-top">
                  <span>01</span>
                  <div />
                </div>
                <p className="landing-step__progress-name">
                  Effectuez une simulation de tarifs
                </p>
              </div>
              <div className="landing-step__progress-item">
                <div className="landing-step__progress-top">
                  <span>02</span>
                  <div />
                </div>
                <p className="landing-step__progress-name">
                  Recevez votre devis par mail
                </p>
              </div>
              <div className="landing-step__progress-item">
                <div className="landing-step__progress-top">
                  <span>03</span>
                </div>
                <p className="landing-step__progress-name">
                  Souscrivez en ligne
                </p>
              </div>
            </div>
          </div>

          <Button
            onClick={() => {
              changeStep(
                environment.is.default ? "SITUATION" : "BIRTHDATE",
                "forward"
              );
            }}
          >
            Je démarre
          </Button>
        </div>
      </div>
    </div>
  );
}
