import classNames from "classnames";
import { ReactNode } from "react";
import "./infoBloc.scss";

type InfoBlocProps = {
  children: ReactNode;
  className?: string;
  variant?: "normal" | "accent" | "time";
};
export function InfoBloc({
  className,
  children,
  variant = "normal",
}: InfoBlocProps) {
  return (
    <div className={classNames("info-bloc", className)} data-variant={variant}>
      {children}
    </div>
  );
}
