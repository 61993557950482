export function Tip() {
  return (
    <div>
      <h1>Bon à savoir</h1>
      <h2>Quel âge pour souscrire au contrat sâge autonomie ?</h2>
      <p>
        La souscription au contrat sâge autonomie est ouverte aux personnes
        entre 18 et 70 ans
      </p>
    </div>
  );
}
