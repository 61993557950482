import { Emphasis } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ModalContentProps } from "./Modal";
import { ModalCard } from "./ModalCard";
import "./garantieModal.scss";

export function GarantieProtectionJuridiqueModal({ close }: ModalContentProps) {
  return (
    <ModalCard
      close={close}
      className="garantie-modal"
      headerContent={
        <div className="garantie-modal__header">
          <h1>
            <Emphasis>
              Détails des garanties services et protection juridiques ⁽¹⁾
            </Emphasis>
          </h1>
          <p>
            <b>
              Ces garanties sont assurées et réalisées par Solucia Service et
              Protection Juridiques ⁽²⁾.
            </b>
          </p>
        </div>
      }
      bodyContent={
        <div className="garantie-modal__body">
          <div className="garantie-modal__item">
            <h2>
              Service et protection juridiques pour le membre participant, un
              aidant familial ou un proche aidant :
            </h2>
          </div>
          <div className="garantie-modal__item">
            <p className="garantie-modal__item-label">
              Renseignements juridiques par téléphone :
            </p>
            <p>
              Un service d&apos;informations juridiques et documentaires sur les
              différents domaines du droit, dans le cadre de problématiques du
              quotidien et de la vie privée.
            </p>
          </div>
          <div className="garantie-modal__item">
            <p className="garantie-modal__item-label">
              Service d&apos;accompagnement dans le cadre de démarches
              administratives :
            </p>
            <p>
              Assistance dans la constitution de dossiers ou communication sur
              simple appel, de tous les formulaires et informations nécessaires
              à l&apos;aboutissement de démarches administratives.
            </p>
          </div>
          <div className="garantie-modal__item">
            <p className="garantie-modal__item-label">Protection juridique :</p>
            <p>
              Assistance juridique dans le cadre d&apos;un litige dans les
              domaines suivants : consommation, services à la personne, santé,
              travaux d&apos;aménagement, travail.
            </p>
          </div>
          <div className="garantie-modal__notice">
            <p>
              <sup>(1)</sup> Se référer à l&apos;annexe 6, Garantie service et
              protection juridiques du{" "}
              <a
                tabIndex={-1}
                target="_blank"
                href="https://lya-public.s3.eu-west-3.amazonaws.com/tutelaire/REGLEMENT_tutelaire.pdf"
                rel="noreferrer"
              >
                Règlement du contrat sâge autonomie.
              </a>
            </p>
            <p>
              <sup>(2)</sup> Les garanties service et protection juridiques sont
              assurées et réalisées par Solucia Service et Protection
              Juridiques, société anonyme à directoire et conseil de
              surveillance au capital social de 9 600 000 € régie par le Code
              des assurances, dont le siège social est situé 111 avenue de
              France - CS 51519 - 75634 Paris cedex 13 et immatriculée au RCS de
              Paris sous le numéro Siren 481 997 708.
            </p>
          </div>
        </div>
      }
    />
  );
}
