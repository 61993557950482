import { NavButton } from "@src/NavButton";
import "./recap.scss";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { getYear } from "date-fns";
import { LinkButton } from "@src/LinkButton";
import { RecapCard } from "@src/RecapCard";
import { StepProps } from "./Steps";

export type RecapData = undefined;
type RecapProps = StepProps<undefined>;

export function Recap({ changeStep, stepsData }: RecapProps) {
  if (!stepsData.TARIF || !stepsData.BIRTHDATE.birthDate) return null;
  const age = getYear(new Date()) - getYear(stepsData.BIRTHDATE.birthDate);
  return (
    <div className="birthdate-step">
      <div className="stepper__centered-container">
        <div className="stepper__centered-content step__grid step__grid--padding-bottom">
          <NavButton
            onClick={() => {
              changeStep("TARIF", "backward");
            }}
          >
            Précédent
          </NavButton>
          <div className="step__grid-bloc">
            <RecapCard
              age={age}
              couvertureName={stepsData.TARIF.name}
              simulationCotisationMonth={stepsData.TARIF.simulationCotisation}
            />
          </div>
          <div className="step__grid-bloc recap-step__edit-container">
            <LinkButton onClick={() => changeStep("TARIF", "backward")}>
              Modifier mes choix
            </LinkButton>
          </div>
          <Button onClick={() => changeStep("CONTACT_INFOS", "forward")}>
            Suivant
          </Button>
        </div>
      </div>
    </div>
  );
}
