import {
  ProjetPreSouscription,
  ProjetSignature,
  ProjetSouscription,
} from "@src/App";
import { Tip } from "@src/Tip";
import { Dispatch, SetStateAction } from "react";
import { AboutYou, AboutYouData } from "./AboutYou";
import { BirthDate, BirthDateData } from "./BirthDate";
import { CheckYourMail, CheckYourMailData } from "./CheckYourMail";
import { ConfirmSimulation, ConfirmSimulationData } from "./ConfirmSimulation";
import { Consent, ConsentFormValues } from "./Consent";
import {
  ConsentSouscription,
  ConsentSouscriptionData,
} from "./ConsentSouscription";
import { ContactInfos, ContactInfosData } from "./ContactInfos";
import { Eligibilite, EligibiliteFormValues } from "./Eligibilite";
import { Landing, LandingData } from "./Landing";
import { MandatPrelevement, MandatPrelevementData } from "./MandatPrelevement";
import { NumSecu, NumSecuData } from "./NumSecu";
import {
  PiecesJustificatives,
  PiecesJustificativesData,
} from "./PiecesJustificatives";
import { Recap, RecapData } from "./Recap";
import { RecapSignature, RecapSignatureData } from "./RecapSignature";
import { RecapSouscription, RecapSouscriptionData } from "./RecapSouscription";
import { Situation, SituationData } from "./Situation";
import { Success, SuccessData } from "./Success";
import { Tarif } from "./Tarif";

export type StepProps<TData> = {
  projectId?: string;
  projetSouscription?: ProjetSouscription;
  projetPreSouscription?: ProjetPreSouscription;
  projetSignature?: ProjetSignature;
  setProjetSignature: Dispatch<SetStateAction<ProjetSignature | undefined>>;
  setProjetPreSouscription: Dispatch<
    SetStateAction<ProjetPreSouscription | undefined>
  >;
  data: TData;
  changeStep: (
    stepName: StepName,
    transitionDirection: "forward" | "backward"
  ) => void;
  setData: (data: TData) => void;
  stepsData: StepsData;
};

export enum MacroStep {
  Simulate,
  Receive,
  Subscribe,
}

export const STEPS = {
  LANDING: {
    progress: undefined,
    component: Landing,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Simulate,
  },
  SITUATION: {
    progress: 20,
    component: Situation,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Simulate,
  },
  BIRTHDATE: {
    progress: 50,
    component: BirthDate,
    tipsComponent: Tip,
    minifiedSidePane: false,
    macroStep: MacroStep.Simulate,
  },
  TARIF: {
    progress: 70,
    component: Tarif,
    tipsComponent: undefined,
    minifiedSidePane: true,
    macroStep: MacroStep.Simulate,
  },
  RECAP: {
    progress: 100,
    component: Recap,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Simulate,
  },
  CONTACT_INFOS: {
    progress: undefined,
    component: ContactInfos,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Receive,
  },
  CONFIRM_SIMULATION: {
    progress: undefined,
    component: ConfirmSimulation,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Receive,
  },
  CONSENT: {
    progress: 10,
    component: Consent,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Subscribe,
  },
  ABOUT_YOU: {
    progress: 30,
    component: AboutYou,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Subscribe,
  },
  CHECK_YOUR_MAIL: {
    progress: 30,
    component: CheckYourMail,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Subscribe,
  },
  RECAP_SOUSCRIPTION: {
    progress: 40,
    component: RecapSouscription,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Subscribe,
  },
  NUM_SECU: {
    progress: 50,
    component: NumSecu,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Subscribe,
  },
  ELIGIBILITE: {
    progress: 20,
    component: Eligibilite,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Subscribe,
  },
  CONSENT_SOUSCRIPTION: {
    progress: 60,
    component: ConsentSouscription,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Subscribe,
  },
  MANDAT_PRELEVEMENT: {
    progress: 70,
    component: MandatPrelevement,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Subscribe,
  },
  PIECES_JUSTIFICATIVES: {
    progress: 80,
    component: PiecesJustificatives,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Subscribe,
  },
  RECAP_SIGNATURE: {
    progress: 90,
    component: RecapSignature,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Subscribe,
  },
  SUCCESS: {
    progress: undefined,
    component: Success,
    tipsComponent: undefined,
    minifiedSidePane: false,
    macroStep: MacroStep.Subscribe,
  },
};
export type StepsData = {
  LANDING: LandingData;
  SITUATION: SituationData;
  BIRTHDATE: BirthDateData;
  CONTACT_INFOS: ContactInfosData;
  CONFIRM_SIMULATION: ConfirmSimulationData;
  CONSENT: ConsentFormValues | undefined;
  TARIF?: Tarif;
  RECAP: RecapData;
  ABOUT_YOU: AboutYouData;
  CHECK_YOUR_MAIL: CheckYourMailData;
  RECAP_SOUSCRIPTION: RecapSouscriptionData;
  NUM_SECU: NumSecuData;
  CONSENT_SOUSCRIPTION: ConsentSouscriptionData;
  MANDAT_PRELEVEMENT: MandatPrelevementData;
  PIECES_JUSTIFICATIVES: PiecesJustificativesData;
  RECAP_SIGNATURE: RecapSignatureData;
  SUCCESS: SuccessData;
  ELIGIBILITE: EligibiliteFormValues;
};

export type StepName = keyof typeof STEPS;
