import { FooterMUTAMIDefault } from "@src/Footer/MUTAMI/FooterMUTAMIDefault";
import { FooterMUTAMIStepAboutYou } from "@src/Footer/MUTAMI/FooterMUTAMIStepAboutYou";
import { FooterMUTAMIStepBirthDate } from "@src/Footer/MUTAMI/FooterMUTAMIStepBirthDate";
import { FooterMUTAMIStepContactInfos } from "@src/Footer/MUTAMI/FooterMUTAMIStepContactInfos";
import { FooterMUTAMIStepEligibilite } from "@src/Footer/MUTAMI/FooterMUTAMIStepEligibilite";
import { FooterMUTAMIStepMandatPrelevement } from "@src/Footer/MUTAMI/FooterMUTAMIStepMandatPrelevement";
import { FooterMUTAMIStepNumSecu } from "@src/Footer/MUTAMI/FooterMUTAMIStepNumSecu";
import { FooterMUTAMIStepPiecesJustificatives } from "@src/Footer/MUTAMI/FooterMUTAMIStepPiecesJustificatives";
import { StepName } from "@src/Steps/Steps";
import React from "react";

type FooterByStepNameProps = {
  stepName: StepName;
};

export function FooterMUTAMIByStepName({ stepName }: FooterByStepNameProps) {
  switch (stepName) {
    case "BIRTHDATE":
      return <FooterMUTAMIStepBirthDate />;
    case "CONTACT_INFOS":
      return <FooterMUTAMIStepContactInfos />;
    case "ELIGIBILITE":
      return <FooterMUTAMIStepEligibilite />;
    case "ABOUT_YOU":
      return <FooterMUTAMIStepAboutYou />;
    case "NUM_SECU":
      return <FooterMUTAMIStepNumSecu />;
    case "MANDAT_PRELEVEMENT":
      return <FooterMUTAMIStepMandatPrelevement />;
    case "PIECES_JUSTIFICATIVES":
      return <FooterMUTAMIStepPiecesJustificatives />;
    case "CONSENT":
      return <FooterMUTAMIDefault footnoteReference />;
    default:
      return <FooterMUTAMIDefault />;
  }
}
