import { ReactElement, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import "./modal.scss";

type CloseModalFunction<TResult> = (result?: TResult) => void;

export type ModalContentProps<TResult = undefined> = {
  close: CloseModalFunction<TResult>;
};

const TRANSITION_LENGTH = 200;
function useModalTransition(isOpen?: boolean) {
  const [modalState, setModalState] = useState({
    isOpen: false,
    isMounted: false,
  });
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isOpen) {
      clearTimeout(timeoutRef.current);
      setModalState({
        isOpen: true,
        isMounted: true,
      });
    } else {
      setModalState({
        isOpen: false,
        isMounted: true,
      });
      timeoutRef.current = setTimeout(() => {
        setModalState({
          isOpen: false,
          isMounted: false,
        });
      }, TRANSITION_LENGTH);
    }
  }, [isOpen]);

  return [modalState.isOpen, modalState.isMounted];
}

type ModalProps<TResult> = {
  isOpen?: boolean;
  close: CloseModalFunction<TResult>;
  Content: (props: ModalContentProps<TResult>) => ReactElement;
};

export function Modal<TResult = undefined>({
  isOpen,
  close,
  Content,
}: ModalProps<TResult>) {
  const [contentIsOpen, contentIsMounted] = useModalTransition(isOpen);

  return createPortal(
    <div className="modal" data-isopen={contentIsOpen}>
      <div aria-hidden className="modal__backdrop" onClick={() => close()} />
      <div className="modal__content">
        {contentIsMounted && <Content close={close} />}
      </div>
    </div>,
    document.getElementById("root") as HTMLElement
  );
}
