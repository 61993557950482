import axios from "axios";
import {
  Form,
  InputMask,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { NavButton } from "@src/NavButton";
import {
  Button,
  Emphasis,
  FormAction,
  FormBloc,
  FormField,
  FormGrid,
  FormNotice,
  FormRow,
  FormSubTitle,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { AutoCompleteCityDialog } from "@src/AutoCompleteCityDialog";
import "./mandatPrelevement.scss";
import { Card } from "@src/Card";
import { InfoBloc } from "@src/InfoBloc";
import { Collapse } from "react-collapse";
import { useState } from "react";
import { LoaderOverlay } from "@src/LoaderOverlay";
import { ProjetSignature } from "@src/App";
import { displayToast } from "@src/Toaster";
import { captureException } from "@sentry/react";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { StepProps } from "./Steps";
import {
  CONSENTEMENT_ARTICLE_18,
  CONSENTEMENT_EXCLUSIONS_PRODUIT,
  CONSENTEMENT_EXCLUSION_14,
  CONSENTEMENT_FAUSSE_DECLA,
  CONSENTEMENT_INVALIDITE,
  CONSENTEMENT_PRODUIT,
  CONSENTEMENT_REGLEMENT_CG,
} from "./ConsentSouscription";

export type MandatPrelevementData =
  | {
      name: string;
      surname: string;
      address: string;
      postalCode: string;
      city: string;
      iban: string;
      bic: string;
    }
  | undefined;
type MandatPrelevementProps = StepProps<MandatPrelevementData>;

function cleanMaskedInput(val: unknown) {
  if (typeof val !== "string") return undefined;
  return val.replace(/\s/g, "");
}

const schema = zod.object({
  name: zod.string({ required_error: "Champ requis" }).min(1, "Champ requis"),
  surname: zod
    .string({ required_error: "Champ requis" })
    .min(1, "Champ requis"),
  address: zod
    .string({ required_error: "Champ requis" })
    .min(1, "Champ requis"),
  postalCode: zod
    .string({ required_error: "Champ requis" })
    .min(1, "Champ requis")
    .regex(/^\d{5}/, { message: "Code invalide" }),
  city: zod.string({ required_error: "Champ requis" }).min(1, "Champ requis"),
  bic: zod.preprocess(
    cleanMaskedInput,
    zod
      .string({ required_error: "Champ requis" })
      .min(1, "Champ requis")
      .regex(/^\w{8}$|\w{11}$/, "Code BIC invalide")
  ),
  iban: zod
    .string({ required_error: "Champ requis" })
    .max(34)
    .refine(async (val) => {
      try {
        const res = await axios.get<{ isValid: false }>(
          `//${process.env.VITE_URL_GATEWAY}/iban/check/${val}`
        );
        return res.data.isValid;
      } catch (err) {
        return false;
      }
    }, "Numéro IBAN invalide"),
});

type FormValues = zod.infer<typeof schema>;

export function MandatPrelevement({
  data,
  projectId,
  stepsData,
  setProjetSignature,
  changeStep,
  projetSouscription,
}: MandatPrelevementProps) {
  const [infoBlocIsOpen, setInfoBlocIsOpen] = useState(false);
  const formConfig = useFormConfig<typeof schema, FormValues>({
    schema,
    defaultValues: {
      ...data,
      name: data?.name ?? projetSouscription?.assure.name,
      surname: data?.surname ?? projetSouscription?.assure.surname,
      address: data?.address ?? projetSouscription?.assure.address,
      postalCode: data?.address ?? projetSouscription?.assure.postalCode,
      city: data?.city ?? projetSouscription?.assure.city,
    },
  });

  const postalCode = formConfig.watch("postalCode");

  if (!projectId || !stepsData.CONSENT_SOUSCRIPTION) return null;

  const handleSubmit = async (submittedData: FormValues) => {
    const consentements: { type: string; description: string }[] = [];
    if (stepsData.CONSENT_SOUSCRIPTION?.consentDocInfoProduit) {
      consentements.push({
        type: "CONSENTEMENT_PRODUIT",
        description: CONSENTEMENT_PRODUIT,
      });
    }
    if (stepsData.CONSENT_SOUSCRIPTION?.consentExclusionReg) {
      consentements.push({
        type: "CONSENTEMENT_EXCLUSIONS_PRODUIT",
        description: CONSENTEMENT_EXCLUSIONS_PRODUIT,
      });
    }
    if (stepsData.CONSENT_SOUSCRIPTION?.consentFausseDec) {
      consentements.push({
        type: "CONSENTEMENT_FAUSSE_DECLA",
        description: CONSENTEMENT_FAUSSE_DECLA,
      });
    }
    if (stepsData.CONSENT_SOUSCRIPTION?.consentTermesProduit) {
      consentements.push({
        type: "CONSENTEMENT_REGLEMENT_CG",
        description: CONSENTEMENT_REGLEMENT_CG,
      });
    }
    if (stepsData.CONSENT_SOUSCRIPTION?.consentInvalidite) {
      consentements.push({
        type: "CONSENTEMENT_INVALIDITE",
        description: CONSENTEMENT_INVALIDITE,
      });
    }
    if (stepsData.CONSENT_SOUSCRIPTION?.consentExclusion14) {
      consentements.push({
        type: "CONSENTEMENT_EXCLUSION_14",
        description: CONSENTEMENT_EXCLUSION_14,
      });
    }
    if (stepsData.CONSENT_SOUSCRIPTION?.consentArticle18) {
      consentements.push({
        type: "CONSENTEMENT_ARTICLE_18",
        description: CONSENTEMENT_ARTICLE_18,
      });
    }

    try {
      const res = await axios.post<ProjetSignature>(
        `//${process.env.VITE_URL_GATEWAY}/souscrire/${projectId}`,
        {
          consentements,
          numeroSecu: stepsData.NUM_SECU?.numSecu,
          rib: {
            nom: submittedData.name,
            prenom: submittedData.surname,
            voie: submittedData.address,
            codePostal: submittedData.postalCode,
            ville: submittedData.city,
            iban: cleanMaskedInput(submittedData.iban),
            bic: cleanMaskedInput(submittedData.bic),
          },
        }
      );
      res.data.assure.birthDate = new Date(res.data.assure.birthDate);
      setProjetSignature(res.data);
      changeStep("PIECES_JUSTIFICATIVES", "forward");
    } catch (err) {
      captureException(err);
      console.error(err);
      displayToast("Une erreur inconnue est survenue", "error");
      throw err;
    }
  };

  return (
    <>
      <LoaderOverlay isLoading={formConfig.formState.isSubmitting} />
      <div className="mandat-prelevement-step">
        <div className="stepper__centered-container">
          <div className="stepper__centered-content step__grid step__grid--padding-bottom">
            <NavButton
              onClick={() => {
                changeStep("CONSENT_SOUSCRIPTION", "backward");
              }}
            >
              Précédent
            </NavButton>
            <Form
              className="mandat-prelevement-step__form"
              formConfig={formConfig}
              onSubmit={handleSubmit}
            >
              <FormGrid>
                <FormTitle>
                  <h1>
                    Complétez votre mandat de{" "}
                    <Emphasis>prélèvement SEPA</Emphasis>
                  </h1>
                </FormTitle>
                <InfoBloc
                  className="mandat-prelevement-step__info-bloc"
                  variant="accent"
                >
                  <p>
                    En signant ce formulaire de mandat, vous autorisez la
                    mutuelle TUTÉLAIRE à envoyer des instructions à votre banque
                    pour débiter votre compte, et votre banque à débiter votre
                    compte conformément aux instructions de la mutuelle
                    TUTÉLAIRE.
                  </p>
                  <Collapse isOpened={infoBlocIsOpen}>
                    <p>
                      Vous bénéficiez du droit d&apos;être remboursé par votre
                      banque selon les conditions décrites dans la convention
                      que vous avez passé avec elle. Une demande de
                      remboursement doit être présentée :
                    </p>
                    <ul>
                      <li>
                        Dans les 8 semaines suivant la date de débit de votre
                        compte pour un prélèvement autorisé ;
                      </li>
                      <li>
                        Sans tarder et au plus tard dans les 13 mois en cas de
                        prélèvement non autorisé ;
                      </li>
                    </ul>
                    <p>
                      Vos droits concernant le présent mandat sont expliqués
                      dans un document que vous pouvez obtenir auprès de votre
                      banque.
                    </p>
                  </Collapse>
                  <div className="mandat-prelevement-step__collapse-button-wrapper">
                    <button
                      data-isopen={infoBlocIsOpen}
                      className="mandat-prelevement-step__collapse-button"
                      type="button"
                      onClick={() => {
                        setInfoBlocIsOpen(!infoBlocIsOpen);
                      }}
                    >
                      {infoBlocIsOpen ? "Masquer" : "En savoir plus"}
                    </button>
                  </div>
                </InfoBloc>
                <FormNotice>
                  <p>
                    <Emphasis>*</Emphasis> Champs obligatoires
                  </p>
                </FormNotice>
                <FormSubTitle>
                  <h2>Titulaire</h2>
                </FormSubTitle>
                <FormRow>
                  <FormField>
                    <InputText<FormValues> name="name" label="Nom" required />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <InputText<FormValues>
                      name="surname"
                      label="Prénom"
                      required
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <InputText<FormValues>
                      name="address"
                      label="Adresse postale"
                      required
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField
                    size="half"
                    className="mandat-prelevement-step__autocomplete-field"
                  >
                    <InputMask<FormValues>
                      mask="99999"
                      name="postalCode"
                      label="Code postal"
                      required
                    />
                    <AutoCompleteCityDialog
                      className="mandat-prelevement-step__autocomplete-card"
                      postalCode={postalCode}
                      onSelect={(city) => {
                        formConfig.setValue("city", city, {
                          shouldValidate: true,
                          shouldTouch: true,
                        });
                        const node =
                          document.querySelector<HTMLInputElement>(
                            'input[name="city"]'
                          );
                        if (node) node.focus();
                      }}
                    />
                  </FormField>
                  <FormField size="half">
                    <InputText<FormValues> name="city" label="Ville" required />
                  </FormField>
                </FormRow>
                <FormSubTitle>
                  <h2>Compte à débiter</h2>
                </FormSubTitle>
                <FormRow>
                  <FormField>
                    <InputMask
                      className="mandat-prelevement-step__uppercase-input"
                      name="iban"
                      mask="**** **** **** **** **** **** ***"
                      maskPlaceholder=""
                      label="IBAN"
                      required
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <InputMask
                      className="mandat-prelevement-step__uppercase-input"
                      name="bic"
                      label="BIC"
                      mask="**** ** ** ***"
                      onChange={(nextValue, isTouched) => {
                        formConfig.setValue("bic", nextValue.toUpperCase(), {
                          shouldValidate: isTouched,
                        });
                      }}
                      required
                    />
                  </FormField>
                </FormRow>

                <FormSubTitle>
                  <h2>Informations sur le créancier</h2>
                </FormSubTitle>
                <FormBloc>
                  <Card variant="accent">
                    <h2 className="mandat-prelevement-step__card-title">
                      Mutuelle Tutélaire
                    </h2>
                    <div className="mandat-prelevement-step__info-grid">
                      <div className="mandat-prelevement-step__info-item">
                        <p>Adresse postale</p>
                        <p>
                          157 avenue de France
                          <br />
                          75013 Paris
                        </p>
                      </div>
                      <div className="mandat-prelevement-step__info-item">
                        <p>Identifiant créancier SEPA</p>
                        <p>FR88ZZZ386329</p>
                      </div>
                      <div className="mandat-prelevement-step__info-item">
                        <p>Type de paiement</p>
                        <p>Paiement mensuel récurrent / répétitif</p>
                      </div>
                    </div>
                  </Card>
                </FormBloc>
                <FormAction>
                  <Button
                    type="submit"
                    disabled={
                      formConfig.formState.isSubmitting ||
                      formConfig.formState.isSubmitSuccessful
                    }
                  >
                    Suivant
                  </Button>
                </FormAction>
              </FormGrid>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
