import {
  Button,
  Emphasis,
  FormAction,
  FormField,
  FormGrid,
  FormNotice,
  FormRow,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { NavButton } from "@src/NavButton";
import {
  Form,
  InputCheckbox,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { environment } from "@src/MarqueBlanche";
import { StepProps } from "./Steps";
import "./consentSouscription.scss";

export type ConsentSouscriptionData =
  | {
      consentDocInfoProduit: boolean;
      consentExclusionReg: boolean;
      consentFausseDec: boolean;
      consentTermesProduit: boolean;
      consentInvalidite: boolean;
      consentExclusion14: boolean;
      consentArticle18: boolean;
    }
  | undefined;

type ConsentSouscriptionProps = StepProps<ConsentSouscriptionData>;

export const CONSENTEMENT_PRODUIT =
  "Vous reconnaissez avoir pris connaissance du document d'information sur le produit d'assurance relatif au contrat sâge autonomie, du règlement du contrat sâge autonomie et de ses annexes ainsi que des statuts de Tutélaire";
export const CONSENTEMENT_EXCLUSIONS_PRODUIT =
  "Vous reconnaissez avoir pris connaissance des exclusions figurant à l'article 3 du règlement du contrat sâge autonomie aux termes desquelles la souscription du contrat sâge autonomie implique que le membre participant : - ne bénéficie d'aucune prise en charge au titre d'une invalidité dont les critères de reconnaissance sont définis en annexe 3 de ce même règlement ; - ne soit pas reconnu en état de dépendance, c'est-à-dire ne soit pas rattaché à l'un des Groupes Iso-Ressources ouvrant droit à l'Allocation Personnalisée d'Autonomie ; - n'ait engagé aucune démarche visant à la reconnaissance d'une invalidité ou d'un état de dépendance.";
export const CONSENTEMENT_INVALIDITE =
  "J'ai pris connaissance des critères de reconnaissance d'invalidité qui conduisent à l'impossibilité de souscrire le contrat SÂGE AUTONOMIE en application des dispositions de l'article 3, ces critères figurant en annexe 3 du règlement SÂGE AUTONOMIE.";
export const CONSENTEMENT_EXCLUSION_14 =
  "Vous avez pris connaissance des exclusions figurant à l'article 14 du règlement du contrat sâge autonomie";
export const CONSENTEMENT_ARTICLE_18 =
  "Vous reconnaissez avoir pris connaissance des dispositions relatives à la prise d'effet des garanties en couverture du risque dépendance et des conséquences de la survenance d'un état de dépendance durant le délai d'attente, telles qu'énoncées à l'article 18 du règlement du contrat sâge autonomie";
export const CONSENTEMENT_FAUSSE_DECLA =
  "Vous reconnaissez avoir pris connaissance des dispositions relatives à la fausse déclaration et à ses conséquences, telles qu'énoncées à l'article 13 du règlement du contrat sâge autonomie";
export const CONSENTEMENT_REGLEMENT_CG =
  "Vous acceptez les termes du règlement du contrat sâge autonomie  et de ses annexes";

const schema = zod.object({
  consentDocInfoProduit: zod
    .boolean({ required_error: "Champ requis" })
    .refine((val) => val === true, "Champ requis"),
  consentExclusionReg: zod
    .boolean({ required_error: "Champ requis" })
    .refine((val) => val === true, "Champ requis"),
  consentFausseDec: zod
    .boolean({ required_error: "Champ requis" })
    .refine((val) => val === true, "Champ requis"),
  consentTermesProduit: zod
    .boolean({ required_error: "Champ requis" })
    .refine((val) => val === true, "Champ requis"),
  consentInvalidite: zod
    .boolean({ required_error: "Champ requis" })
    .refine((val) => val === true, "Champ requis"),
  consentExclusion14: zod
    .boolean({ required_error: "Champ requis" })
    .refine((val) => val === true, "Champ requis"),
  consentArticle18: zod
    .boolean({ required_error: "Champ requis" })
    .refine((val) => val === true, "Champ requis"),
});
type FormValues = zod.infer<typeof schema>;

export function ConsentSouscription({
  changeStep,
  data,
  setData,
}: ConsentSouscriptionProps) {
  const formConfig = useFormConfig<typeof schema, FormValues>({
    schema,
    defaultValues: data,
  });

  const handleSubmit = (submittedData: FormValues) => {
    setData(submittedData);
    changeStep("MANDAT_PRELEVEMENT", "forward");
  };

  return (
    <div className="consent-souscription-step">
      <div className="stepper__centered-container">
        <div className="stepper__centered-content step__grid step__grid--padding-bottom">
          <NavButton
            onClick={() => {
              changeStep("NUM_SECU", "backward");
            }}
          >
            Précédent
          </NavButton>

          <Form formConfig={formConfig} onSubmit={handleSubmit}>
            <FormGrid>
              <FormTitle>
                <h1>
                  La conclusion de votre contrat sera effective si et seulement
                  si le souscripteur a été en mesure de valider les conditions
                  suivantes :
                </h1>
              </FormTitle>
              <FormNotice>
                <p>
                  <Emphasis>*</Emphasis> Mention obligatoire
                </p>
              </FormNotice>
              <FormRow>
                <FormField>
                  <InputCheckbox<FormValues>
                    name="consentDocInfoProduit"
                    required
                    label={
                      <>
                        Vous reconnaissez avoir pris connaissance du{" "}
                        <a
                          tabIndex={-1}
                          target="_blank"
                          href={environment.current.docDIPA}
                          rel="noreferrer"
                        >
                          document d&apos;information sur le produit
                          d&apos;assurance
                        </a>{" "}
                        relatif au contrat sâge autonomie, du{" "}
                        <a
                          tabIndex={-1}
                          target="_blank"
                          href={environment.current.docReglement}
                          rel="noreferrer"
                        >
                          règlement
                        </a>{" "}
                        du contrat sâge autonomie et de ses annexes ainsi que
                        des{" "}
                        <a
                          tabIndex={-1}
                          target="_blank"
                          href={environment.current.docStatut}
                          rel="noreferrer"
                        >
                          statuts
                        </a>{" "}
                        de Tutélaire
                      </>
                    }
                  />
                </FormField>
              </FormRow>
              <FormRow>
                <FormField>
                  <InputCheckbox<FormValues>
                    name="consentExclusionReg"
                    label={
                      <>
                        <p>
                          Vous reconnaissez avoir pris connaissance des
                          exclusions figurant à l&apos;article 3 du{" "}
                          <a
                            tabIndex={-1}
                            target="_blank"
                            href={environment.current.docReglement}
                            rel="noreferrer"
                          >
                            règlement
                          </a>{" "}
                          du contrat sâge autonomie aux termes desquelles la
                          souscription du contrat sâge autonomie implique que le
                          membre participant&nbsp;:
                        </p>
                        <ul className="consent-souscription-step__exclusions-list">
                          <li>
                            ne bénéficie d&apos;aucune prise en charge au titre
                            d&apos;une invalidité dont les critères de
                            reconnaissance sont définis en annexe 3 de ce même
                            règlement ;
                          </li>
                          <li>
                            ne soit pas reconnu en état de dépendance,
                            c&apos;est-à-dire ne soit pas rattaché à l&apos;un
                            des Groupes Iso-Ressources ouvrant droit à
                            l&apos;Allocation Personnalisée d&apos;Autonomie ;
                          </li>
                          <li>
                            n&apos;ait engagé aucune démarche visant à la
                            reconnaissance d&apos;une invalidité ou d&apos;un
                            état de dépendance.
                            <Emphasis>*</Emphasis>
                          </li>
                        </ul>
                      </>
                    }
                  />
                </FormField>
              </FormRow>
              <FormRow>
                <FormField>
                  <InputCheckbox<FormValues>
                    name="consentInvalidite"
                    required
                    label={
                      <>
                        Vous avez pris connaissance des critères de
                        reconnaissance d&apos;invalidité qui conduisent à
                        l&apos;impossibilité de souscrire le contrat sâge
                        autonomie en application des dispositions de
                        l&apos;article 3, ces critères figurant en annexe 3 du{" "}
                        <a
                          tabIndex={-1}
                          target="_blank"
                          href={environment.current.docReglement}
                          rel="noreferrer"
                        >
                          règlement
                        </a>{" "}
                        du contrat sâge autonomie
                      </>
                    }
                  />
                </FormField>
              </FormRow>
              <FormRow>
                <FormField>
                  <InputCheckbox<FormValues>
                    name="consentExclusion14"
                    required
                    label={
                      <>
                        Vous avez pris connaissance des exclusions figurant à
                        l&apos;article 14 du{" "}
                        <a
                          tabIndex={-1}
                          target="_blank"
                          href={environment.current.docReglement}
                          rel="noreferrer"
                        >
                          règlement
                        </a>{" "}
                        du contrat sâge autonomie
                      </>
                    }
                  />
                </FormField>
              </FormRow>
              <FormRow>
                <FormField>
                  <InputCheckbox<FormValues>
                    name="consentArticle18"
                    required
                    label={
                      <>
                        Vous reconnaissez avoir pris connaissance des
                        dispositions relatives à la prise d&apos;effet des
                        garanties en couverture du risque dépendance et des
                        conséquences de la survenance d&apos;un état de
                        dépendance durant le délai d&apos;attente, telles
                        qu&apos;énoncées à l&apos;article 18 du{" "}
                        <a
                          tabIndex={-1}
                          target="_blank"
                          href={environment.current.docReglement}
                          rel="noreferrer"
                        >
                          règlement
                        </a>{" "}
                        du contrat sâge autonomie
                      </>
                    }
                  />
                </FormField>
              </FormRow>
              <FormRow>
                <FormField>
                  <InputCheckbox<FormValues>
                    name="consentFausseDec"
                    required
                    label={
                      <>
                        Vous reconnaissez avoir pris connaissance des
                        dispositions relatives à la fausse déclaration et à ses
                        conséquences, telles qu&apos;énoncées à l&apos;article
                        13 du{" "}
                        <a
                          tabIndex={-1}
                          target="_blank"
                          href={environment.current.docReglement}
                          rel="noreferrer"
                        >
                          règlement
                        </a>{" "}
                        du contrat sâge autonomie
                      </>
                    }
                  />
                </FormField>
              </FormRow>
              <FormRow>
                <FormField>
                  <InputCheckbox<FormValues>
                    name="consentTermesProduit"
                    required
                    label={
                      <>
                        Vous acceptez les termes du{" "}
                        <a
                          tabIndex={-1}
                          target="_blank"
                          href={environment.current.docReglement}
                          rel="noreferrer"
                        >
                          règlement
                        </a>{" "}
                        du contrat sâge autonomie et de ses annexes
                      </>
                    }
                  />
                </FormField>
              </FormRow>
              <FormAction>
                <Button
                  type="submit"
                  disabled={
                    formConfig.formState.isSubmitting ||
                    formConfig.formState.isSubmitSuccessful
                  }
                >
                  Suivant
                </Button>
              </FormAction>
            </FormGrid>
          </Form>
        </div>
      </div>
    </div>
  );
}
