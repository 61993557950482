export function FooterAMELLISStepBirthDate() {
  return (
    <p>
      <small>
        Les informations recueillies dans le présent formulaire, ont pour
        finalités la réalisation du devis et la gestion du risque. Vous disposez
        des droits d&apos;accès, de rectification, d&apos;opposition, de
        portabilité, d&apos;effacement et de limitation du traitement vous
        concernant. Pour les exercer, vous pouvez directement contacter le
        Délégué à la Protection des Données : par email à l&apos;adresse
        électronique suivante dpo@amellis.fr. Pour permettre le traitement de
        votre demande d&apos;exercice de droits, il conviendra d&apos;indiquer
        le(s) droit(s) que vous souhaitez exercer et de justifier de votre
        identité. Pour plus d&apos;informations relatives à la gestion de vos
        données, vous pouvez à tout moment consulter notre politique de
        protection des données sur le site internet https://amellis.fr/ ou la
        demander par courrier simple au responsable de traitement de Amellis
        Mutuelles. Si vous estimez, après nous avoir contactés, que vos droits «
        Informatique et Libertés » ne sont pas respectés, vous pouvez adresser
        une réclamation à la CNIL directement sur son site interne via le
        formulaire prévu à cet effet : https://www.cnil.fr/fr/plaintes soit par
        courrier à l&apos;adresse suivante : CNIL – 3 Place de Fontenoy – TSA
        80715 – 75334 PARIS CEDX 07.
      </small>
      <small>
        Le contrat sâge autonomie vous est proposé par Amellis Mutuelles, dont
        le siège social est situé 8-12 rue de la Poyat, 39200 Saint-Claude,
        mutuelle soumise aux dispositions du Livre II du Code de la mutualité n°
        SIREN 775 597 768 et au contrôle de l&apos;Autorité de Contrôle
        Prudentiel et de Résolution (A.C.P.R. : 4 place de Budapest – CS 92459 –
        75 436 Paris Cedex 9). Elle agit en qualité de distributeur de
        Tutélaire.
      </small>
      <small>
        Mutuelle soumise aux dispositions du livre II du Code de la mutualité,
        dont le siège social est situé 157 avenue de France – 75013 Paris et
        immatriculée sous le numéro Siren 775 682 164.
      </small>
      <small>
        Les garanties dépendance sont assurées et réalisées par la mutuelle
        Tutélaire. Les garanties service et protection juridiques sont assurées
        et réalisées par Solucia Service et Protection Juridiques, société
        anonyme à directoire et conseil de surveillance au capital social de 9
        600 000 € régie par le Code des assurances, dont le siège social est
        situé 111 avenue de France – CS 51519 – 75634 Paris cedex 13 et
        immatriculée au RCS de Paris sous le numéro Siren 481 997 708.
      </small>
      <small>
        Les garanties d&apos;assistance sont assurées et réalisées par
        Ressources Mutuelles Assistances, union d&apos;assistance soumise aux
        dispositions du Livre II du Code de la mutualité, dont le siège social
        est situé 46 rue du Moulin - CS 32427 - 44124 Vertou Cedex et
        immatriculée sous le numéro Siren 444 269 682.
      </small>
    </p>
  );
}
