import { FooterMCAByStepName } from "@src/Footer/MCA/FooterMCAByStepName";
import { StepName } from "@src/Steps/Steps";
import { MouseEvent } from "react";

type FooterLegalMCAProps = {
  stepName: StepName;
};

export function ProtectionDonneesMCA() {
  const openMailTo = (e: MouseEvent) => {
    window.open("mailto:donneespersonnelles@tutelaire.fr", "mail");
    e.preventDefault();
  };

  return (
    <p>
      <small>
        Le contrat sâge autonomie vous est proposé par la Mutuelle
        Complémentaire d&apos;Alsace (MCA), mutuelle soumise aux dispositions du
        livre II du Code de la mutualité, agissant en qualité de distributeur de
        Tutélaire, dont le siège social est situé 6 route de Rouffach - CS 40062
        – 68027 Colmar Cedex et immatriculée sous le numéro Siren 778 900 027.
        <br />
        <span style={{ textDecoration: "underline" }}>
          Données personnelles :
        </span>{" "}
        Vous disposez des droits d&apos;accès, de rectification,
        d&apos;opposition, de portabilité, d&apos;effacement et de limitation du
        traitement vous concernant.
        <br />
        Pour l&apos;exercice de vos droits, adressez un courrier simple au
        responsable de traitement : Tutélaire, 157 avenue de France, 75013
        Paris, ou un courriel à :{" "}
        <a
          href="mailto:donneespersonnelles@tutelaire.fr"
          target="_blank"
          rel="noreferrer"
          onClick={openMailTo}
        >
          donneespersonnelles@tutelaire.fr
        </a>
        .
        <br />
        Pour plus d&apos;informations relatives à la gestion de vos données,
        vous pouvez à tout moment consulter notre{" "}
        <a
          href="https://www.tutelaire.fr/protection-des-donnees"
          target="_blank"
          rel="noreferrer"
        >
          politique de confidentialité
        </a>{" "}
        sur le site internet{" "}
        <a href="https://www.tutelaire.fr" target="_blank" rel="noreferrer">
          www.tutelaire.fr
        </a>{" "}
        ou la demander par courrier simple au responsable de traitement de
        Tutélaire.
      </small>
    </p>
  );
}

export function FooterLegalMCA({ stepName }: FooterLegalMCAProps) {
  return (
    <>
      <div className="footer-legal__part">
        <FooterMCAByStepName stepName={stepName} />
      </div>
      <div className="footer-legal__part">
        <div className="footer-legal__logos">
          <img
            className="footer-legal__logo-tutelaire"
            alt="logo tutelaire"
            src="/logo-tutelaire.png"
          />
          <img
            className="footer-legal__logo-mutualite"
            alt="logo mutualité francaise"
            src="/logo-mutualite-francaise.png"
          />
        </div>
        <div className="stepper__mentions-legales">
          <a
            href="https://www.mc-alsace.fr/conditions-generales-utilisation-mentions-legales"
            target="_blank"
            rel="noreferrer"
          >
            Mentions légales
          </a>
        </div>
      </div>
    </>
  );
}
