import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { InfoBulle } from "@src/InfoBulle";
import { InfoBulleButton } from "@src/InfoBulleButton";
import { LinkButton } from "@src/LinkButton";
import { NavButton } from "@src/NavButton";
import { PopperButton } from "@src/PopperButton";
import { formatCurrency } from "@src/utils";
import { ReactComponent as Check } from "@src/svg/check.svg";
import { StepName } from "./Steps";
import { Tarif } from "./Tarif";
import "./tarifMobile.scss";

type TableCellProps = {
  title: JSX.Element;
  desc: JSX.Element;
  content: JSX.Element[];
  actions?: (JSX.Element | undefined)[];
  shiftedContent?: boolean;
};
function TableCell({
  content,
  desc,
  title,
  shiftedContent,
  actions,
}: TableCellProps) {
  return (
    <div className="tarif-mobile-step__table-cell">
      <div className="tarif-mobile-step__table-cell-title">{title}</div>
      <div className="tarif-mobile-step__table-cell-desc">{desc}</div>
      <div
        className="tarif-mobile-step__table-cell-content"
        data-shifted={shiftedContent}
      >
        {content.map((item, index) => (
          <div
            key={index} //eslint-disable-line
            className="tarif-mobile-step__table-cell-item"
          >
            {item}
          </div>
        ))}
      </div>
      {actions && (
        <div className="tarif-mobile-step__table-cell-actions">
          {actions.map((action, index) => (
            <div
                key={index} //eslint-disable-line
              className="tarif-mobile-step__table-cell-action"
            >
              {action || null}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function Included() {
  return (
    <p className="tarif-mobile-step__included">
      <Check className="tarif-mobile-step__included-svg" /> Inclus
    </p>
  );
}

type TarifMobileProps = {
  tarifs: Tarif[];
  age: number;
  openModalPJuridique: () => void;
  openModalGassistance: () => void;
  selectTarif: (tarif: Tarif) => () => void;
  changeStep: (
    stepName: StepName,
    transitionDirection: "forward" | "backward"
  ) => void;
};
export function TarifMobile({
  age,
  changeStep,
  openModalGassistance,
  openModalPJuridique,
  selectTarif,
  tarifs,
}: TarifMobileProps) {
  return (
    <div className="tarif-mobile-step">
      <div className="stepper__centered-container">
        <div className="stepper__centered-content step__grid step__grid--padding-bottom">
          <NavButton
            onClick={() => {
              changeStep("BIRTHDATE", "backward");
            }}
          >
            Précédent
          </NavButton>
          <div className="tarif-mobile-step__header">
            <h1>
              Choisissez votre couverture de garanties dépendance pour une souscription à
              l&apos;âge de{" "}
              <PopperButton
                popperPlacement="top-end"
                openOnHover
                withArrow
                renderButton={(props) => (
                  <InfoBulleButton size="large" {...props}>
                    {`${age || 0} ans`}
                  </InfoBulleButton>
                )}
                renderPopperContent={({ setVisible }) => (
                  <InfoBulle onCancel={() => setVisible(false)}>
                    L&apos;âge atteint étant calculé par différence de
                    millésimes entre l&apos;année en cours et l&apos;année de
                    naissance
                  </InfoBulle>
                )}
              />
            </h1>
            <LinkButton
              onClick={() => {
                changeStep("BIRTHDATE", "backward");
              }}
            >
              Modifier ma date de naissance
            </LinkButton>
          </div>
          <div className="tarif-mobile-step__table">
            <div className="tarif-mobile-step__table-header">
              {tarifs.map((tarif, index) => (
                <div
                  key={index} // eslint-disable-line
                  className="tarif-mobile-step__table-header-item"
                >
                  {tarif.name}
                </div>
              ))}
            </div>
            <TableCell
              title={<h2>Garanties dépendance - Capital forfaitaire</h2>}
              desc={
                <p>
                  En cas de rattachement au{" "}
                  <PopperButton
                    popperPlacement="top"
                    openOnHover
                    withArrow
                    renderButton={(props) => (
                      <InfoBulleButton {...props}>
                        GIR 1, 2, 3 et 4
                      </InfoBulleButton>
                    )}
                    renderPopperContent={({ setVisible }) => (
                      <InfoBulle onCancel={() => setVisible(false)}>
                        Degré de perte d&apos;autonomie évalué dans le cadre
                        d&apos;une demande d&apos;allocation personnalisée
                        d&apos;autonomie (APA).
                        <br />
                        Les GIR 1 et 2 correspondent à une dépendance lourde,
                        les GIR 3 et 4 à une dépendance partielle.
                      </InfoBulle>
                    )}
                  />
                </p>
              }
              content={[<p>1500 €</p>, <p>3000 €</p>, <p>4500 €</p>]}
            />
            <TableCell
              title={<h2>Garanties dépendance - Rente mensuelle</h2>}
              desc={
                <p>
                  En cas de rattachement au{" "}
                  <PopperButton
                    popperPlacement="top"
                    openOnHover
                    withArrow
                    renderButton={(props) => (
                      <InfoBulleButton {...props}>GIR 1,2 et 3</InfoBulleButton>
                    )}
                    renderPopperContent={({ setVisible }) => (
                      <InfoBulle onCancel={() => setVisible(false)}>
                        Degré de perte d&apos;autonomie évalué dans le cadre
                        d&apos;une demande d&apos;allocation personnalisée
                        d&apos;autonomie (APA).
                        <br />
                        Les GIR 1 et 2 correspondent à une dépendance lourde,
                        les GIR 3 et 4 à une dépendance partielle.
                      </InfoBulle>
                    )}
                  />
                </p>
              }
              content={[<p>150 €</p>, <p>300 €</p>, <p>450 €</p>]}
            />
            <TableCell
              shiftedContent
              title={<h2>Garantie service et protection juridiques</h2>}
              desc={
                <LinkButton onClick={openModalPJuridique}>
                  Détails des garanties
                </LinkButton>
              }
              content={[<Included />, <Included />, <Included />]}
            />
            <TableCell
              shiftedContent
              title={<h2>Garantie assistance</h2>}
              desc={
                <LinkButton onClick={openModalGassistance}>
                  Détails des garanties
                </LinkButton>
              }
              content={[<Included />, <Included />, <Included />]}
            />
            <TableCell
              title={<h2>Simulation de cotisations</h2>}
              desc={<p>TTC / mois</p>}
              content={[
                <div className="tarif-mobile-step__simulation">
                  <p>
                    <b>{formatCurrency(tarifs[0].simulationCotisation)}</b>
                  </p>
                  <p>
                    {formatCurrency(tarifs[0].simulationCotisation * 12)} / an
                  </p>
                </div>,
                <div className="tarif-mobile-step__simulation">
                  <p>
                    <b>{formatCurrency(tarifs[1].simulationCotisation)}</b>{" "}
                  </p>
                  <p>
                    {formatCurrency(tarifs[1].simulationCotisation * 12)} / an
                  </p>
                </div>,
                <div className="tarif-mobile-step__simulation">
                  <p>
                    <b>{formatCurrency(tarifs[2].simulationCotisation)}</b>{" "}
                  </p>
                  <p>
                    {formatCurrency(tarifs[2].simulationCotisation * 12)} / an
                  </p>
                </div>,
              ]}
              actions={[
                tarifs[0].simulationCotisation !== null ? (
                  <Button
                    className="tarif-mobile-step__button"
                    onClick={selectTarif(tarifs[0])}
                  >
                    Choisir
                  </Button>
                ) : undefined,
                tarifs[1].simulationCotisation !== null ? (
                  <Button
                    className="tarif-mobile-step__button"
                    onClick={selectTarif(tarifs[1])}
                  >
                    Choisir
                  </Button>
                ) : undefined,
                tarifs[2].simulationCotisation !== null ? (
                  <Button
                    className="tarif-mobile-step__button"
                    onClick={selectTarif(tarifs[2])}
                  >
                    Choisir
                  </Button>
                ) : undefined,
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
