import "./linkButton.scss";

type LinkButtonProps = {
  children: string;
  onClick?: () => void;
  type?: "button" | "submit";
};
export function LinkButton({ children, onClick, type }: LinkButtonProps) {
  return (
    <button className="link-button" onClick={onClick} type={type}>
      {children}
    </button>
  );
}
