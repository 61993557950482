import { ChangeEvent, useState } from "react";
import { PJDocument, ProjetSignature } from "@src/App";
import axios from "axios";
import {
  Button,
  Emphasis,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { displayToast } from "@src/Toaster";
import { captureException } from "@sentry/react";
import { StepProps } from "./Steps";
import "./piecesJustificatives.scss";

export type InputPJProps = {
  numero: string;
  document?: PJDocument;
  disabled?: boolean;
  description: string;
  onUpload: (document: PJDocument) => void;
  onDelete: () => void;
};

function InputPJ({
  numero,
  description,
  disabled,
  document,
  onDelete,
  onUpload,
}: InputPJProps) {
  const [status, setStatus] = useState<
    "MISSING" | "SUBMITTING" | "DELETING" | "FAILED" | "SUCCESS"
  >(document ? "SUCCESS" : "MISSING");

  async function handleSubmit(file: File): Promise<PJDocument> {
    const fd = new FormData();

    if (
      ![
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/png",
      ].includes(file.type)
    ) {
      displayToast(
        "Format non pris en charge.\n Extensions acceptées : .jpg, .png, .pdf, .doc, .docx.",
        "error"
      );
      throw Error();
    }

    fd.append("fileUploadedName", file.name);
    fd.append("fileContent", file); // Update the formData object

    try {
      const res = await axios.post<PJDocument>(
        `//${process.env.VITE_URL_GATEWAY}/document/${numero}`,
        fd,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (err) {
      captureException(err);
      console.error(err);
      if ((err as { response?: { status: number } }).response?.status === 413) {
        displayToast("Le fichier est trop volumineux (max 20Mo)", "error");
      } else {
        displayToast(
          "Une erreur est survenue lors de l'envoi du fichier",
          "error"
        );
      }
      throw err;
    }
  }

  function handleDelete() {
    setStatus("DELETING");
    new Promise((resolve) => {
      resolve(undefined);
    })
      .then(() => {
        setStatus("MISSING");
        onDelete();
      })
      .catch(() => {
        setStatus("SUCCESS");
      });
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files || !e.target.files[0]) return;
    setStatus("SUBMITTING");
    handleSubmit(e.target.files[0])
      .then((res) => {
        setStatus("SUCCESS");
        onUpload(res);
      })
      .catch(() => {
        setStatus("FAILED");
      });
  }

  if (status === "SUCCESS" || status === "DELETING") {
    return (
      <div className="pieces-justificatives-step__input-file-success">
        <a target="__blank" href={document?.url}>
          {document?.fileUploadedName}
        </a>
        <button
          onClick={handleDelete}
          disabled={disabled || status === "DELETING"}
        >
          {status === "SUCCESS" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="var(--accent-color)"
              className="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
              <path
                fillRule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              />
            </svg>
          )}
          {status === "DELETING" && (
            <svg
              className="pieces-justificatives-step__input-file-delete-loader"
              version="1.1"
              id="L9"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enableBackground="new 0 0 0 0"
              xmlSpace="preserve"
            >
              <path
                fill="var(--accent-color)"
                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
              >
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="1s"
                  from="0 50 50"
                  to="360 50 50"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          )}
        </button>
      </div>
    );
  }

  return (
    <div
      className="pieces-justificatives-step__input-file"
      data-status={status}
    >
      <input
        disabled={disabled || status === "SUBMITTING"}
        type="file"
        accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png"
        name={numero}
        id={numero}
        onChange={handleChange}
      />
      <label htmlFor={numero}>
        {status === "MISSING" && <span>{description}</span>}
        {status === "SUBMITTING" && (
          <>
            <svg
              version="1.1"
              id="L9"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enableBackground="new 0 0 0 0"
              xmlSpace="preserve"
            >
              <path
                fill="var(--accent-color)"
                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
              >
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="1s"
                  from="0 50 50"
                  to="360 50 50"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
            <span>{description}</span>
          </>
        )}
        {status === "FAILED" && <span>{description}</span>}
      </label>
    </div>
  );
}

export type PiecesJustificativesData = {
  hasSubmitted?: boolean;
  hasResolved?: boolean;
};
type PiecesJustificativesProps = StepProps<PiecesJustificativesData>;

type LocalPJ = {
  numero: string;
  description: string;
  document?: PJDocument;
};

const PJDescriptions = [
  "Pièce d'identité Recto",
  "Pièce d'identité Verso",
  "RIB",
  "Justificatif de domicile de moins de 3 mois",
];
const PJDescriptionsLabels = {
  [PJDescriptions[0]]:
    "Une copie recto de la carte d'identité, du passeport, du permis de conduire ou du titre de séjour en cours de validité",
  [PJDescriptions[1]]:
    "Une copie verso de la carte d'identité, du passeport, du permis de conduire ou du titre de séjour en cours de validité",
  [PJDescriptions[2]]:
    "Un relevé d'identité bancaire (RIB) comportant les mentions IBAN - BIC",
  [PJDescriptions[3]]:
    "Un justificatif de domicile de moins de 3 mois (facture d'eau, électricité, téléphone, gaz, quittance de loyer ou d'assurance, avis d'imposition ou de non-imposition)",
};

export function PiecesJustificatives({
  projectId,
  changeStep,
  projetSignature,
  setProjetSignature,
  setData,
}: PiecesJustificativesProps) {
  const [localPJ, setLocalPJ] = useState(
    (projetSignature as ProjetSignature).piecesJustificatives
      .sort((pjA, pjB) => {
        const ia = PJDescriptions.indexOf(pjA.description);
        const ib = PJDescriptions.indexOf(pjB.description);
        if (ia < ib) return -1;
        if (ia > ib) return 1;
        return 0;
      })
      .reduce<Record<string, LocalPJ>>((acc, curr) => {
        acc[curr.numero] = {
          numero: curr.numero,
          description: curr.description,
          document: curr.document,
        };
        return acc;
      }, {})
  );

  if (!localPJ || !projetSignature) return null;

  async function sign(): Promise<void> {
    if (!projectId) throw Error();
    await axios.put<unknown>(
      `//${process.env.VITE_URL_GATEWAY}/signer/${projectId}`,
      {
        mailAssure: projetSignature?.assure.mail,
      }
    );
  }

  const stepIsValid = !Object.values(localPJ).find((PJ) => !PJ.document);

  return (
    <div className="pieces-justificatives-step">
      <div className="stepper__centered-container">
        <div className="stepper__centered-content step__grid step__grid--padding-bottom pieces-justificatives-step__main">
          <h1>
            Complétez <Emphasis>votre dossier</Emphasis>
          </h1>
          <h3>Veuillez importer les documents suivants :</h3>
          <div className="pieces-justificatives-step__input-list">
            {Object.values(localPJ).map((PJ) => (
              <InputPJ
                document={PJ.document}
                key={PJ.numero}
                numero={PJ.numero}
                description={PJDescriptionsLabels[PJ.description]}
                onDelete={() => {
                  setLocalPJ((state) => ({
                    ...state,
                    [PJ.numero]: {
                      ...state[PJ.numero],
                      document: undefined,
                    },
                  }));
                }}
                onUpload={(document) => {
                  setLocalPJ((state) => ({
                    ...state,
                    [PJ.numero]: {
                      ...state[PJ.numero],
                      document,
                    },
                  }));
                }}
              />
            ))}
          </div>
          <div className="pieces-justificatives-step__action">
            <Button
              disabled={!stepIsValid}
              type="button"
              onClick={() => {
                setData({
                  hasSubmitted: true,
                });
                setProjetSignature({
                  ...projetSignature,
                  piecesJustificatives:
                    projetSignature?.piecesJustificatives.map((pj) => ({
                      ...pj,
                      document: localPJ[pj.numero].document,
                    })),
                });
                changeStep("RECAP_SIGNATURE", "forward");
                sign()
                  .then(() => {
                    setData({
                      hasSubmitted: true,
                      hasResolved: true,
                    });
                  })
                  .catch((err: unknown) => {
                    setData({
                      hasSubmitted: true,
                      hasResolved: true,
                    });
                    captureException(err);
                    console.error(err);
                  });
              }}
            >
              Suivant
            </Button>
            {!stepIsValid && (
              <p>Veuillez importer tous les documents demandés</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
