import "./progressBar.scss";

type ProgressBarProps = {
  progress?: number;
  hidden?: boolean;
};

export function ProgressBar({ progress, hidden }: ProgressBarProps) {
  return (
    <div
      className="progress-bar"
      data-hidden={progress === undefined || hidden}
    >
      <div
        className="progress-bar__inner"
        style={{ "--progress": `${progress || 0}%` } as React.CSSProperties}
        data-progress={`${progress || 0}%`}
      />
    </div>
  );
}
