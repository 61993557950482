import "./loaderAnimation.scss";

export function LoaderAnimation() {
  return (
    <div className="loader-animation">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
