import "./situation.scss";
import {
  Form,
  InputButtonGroup,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormAction,
  FormBloc,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { NavButton } from "@src/NavButton";
import { InfoBloc } from "@src/InfoBloc";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { StepProps } from "./Steps";

export type SituationData =
  | {
      isMemberTutelaire: boolean;
      hasContractSage?: boolean;
    }
  | undefined;
type LandingProps = StepProps<SituationData>;

const schema = zod.object({
  isMemberTutelaire: zod.boolean(),
  hasContractSage: zod.boolean().optional(),
});
type FormValues = zod.infer<typeof schema>;

export function Situation({ data, changeStep, setData }: LandingProps) {
  const formConfig = useFormConfig<typeof schema, FormValues>({
    schema,
    defaultValues: data,
  });

  const isMemberTutelaire = formConfig.watch("isMemberTutelaire");
  const hasContractSage = formConfig.watch("hasContractSage");

  return (
    <div className="situation-step">
      <div className="stepper__centered-container">
        <div className="stepper__centered-content step__grid step__grid--padding-bottom ">
          <NavButton
            onClick={() => {
              changeStep("LANDING", "backward");
            }}
          >
            Précédent
          </NavButton>
          <Form
            className="situation-step__form"
            formConfig={formConfig}
            onSubmit={(submittedData) => {
              setData(submittedData);
              changeStep("BIRTHDATE", "forward");
            }}
          >
            <FormGrid size="large">
              <FormRow>
                <FormField>
                  <InputButtonGroup<FormValues, boolean>
                    label="Êtes-vous déjà adhérent Tutélaire ?"
                    size="large"
                    name="isMemberTutelaire"
                    options={[
                      { label: "Oui", value: true },
                      { label: "Non", value: false },
                    ]}
                  />
                </FormField>
              </FormRow>
              {isMemberTutelaire === true && (
                <>
                  <FormRow>
                    <FormField>
                      <InputButtonGroup<FormValues, boolean>
                        label="Avez-vous un contrat sâge autonomie ?"
                        size="large"
                        name="hasContractSage"
                        options={[
                          { label: "Oui", value: true },
                          { label: "Non", value: false },
                        ]}
                      />
                    </FormField>
                  </FormRow>
                  {hasContractSage === true && (
                    <FormBloc>
                      <InfoBloc>
                        <p>
                          Si vous êtes déjà adhérent sâge autonomie, vous avez
                          la possibilité d&apos;utiliser notre simulateur de
                          cotisation.
                        </p>
                        <p>
                          Si vous souhaitez faire évoluer vos garanties, ce
                          service en ligne n&apos;étant disponible que pour les
                          nouvelles souscriptions, merci de contacter
                          directement nos conseillers pour une demande
                          d&apos;avenant.
                        </p>
                        <p>
                          Vous pouvez joindre nos conseillers par téléphone :
                          <br />
                          - du lundi au vendredi
                          <br />- de 9h00 à 12h30 et de 13h30 à 17h.
                        </p>
                        <img
                          className="situation-step__num-cristal"
                          src="/numCristal.png"
                          alt="Numero cristal : 0 968 398 399"
                        />
                      </InfoBloc>
                    </FormBloc>
                  )}
                </>
              )}
              {(isMemberTutelaire === false ||
                (isMemberTutelaire === true && hasContractSage === false)) && (
                <FormAction>
                  <Button
                    type="submit"
                    variant="outline-accent"
                    disabled={
                      formConfig.formState.isSubmitting ||
                      formConfig.formState.isSubmitSuccessful
                    }
                  >
                    Poursuivre le parcours de simulation
                  </Button>
                </FormAction>
              )}
            </FormGrid>
          </Form>
        </div>
      </div>
    </div>
  );
}
