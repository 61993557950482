import { StepName } from "@src/Steps/Steps";
import { MouseEvent } from "react";

type FooterLegalTutelaireProps = {
  stepName: StepName;
};

export function ProtectionDonneesTutelaire() {
  const openMailTo = (e: MouseEvent) => {
    window.open("mailto:donneespersonnelles@tutelaire.fr", "mail");
    e.preventDefault();
  };

  return (
    <p>
      <small>
        Les informations recueillies dans le présent formulaire, ont pour
        finalité de pouvoir vous rappeler et répondre à vos questions. Vous
        disposez des droits d&apos;accès, de rectification, d&apos;opposition,
        de portabilité, d&apos;effacement et de limitation du traitement vous
        concernant.
        <br />
        Pour l&apos;exercice de vos droits, adressez un courrier simple au
        responsable de traitement : Tutélaire, 157 avenue de France - 75013
        Paris ou un courriel à :{" "}
        <a
          href="mailto:donneespersonnelles@tutelaire.fr"
          target="_blank"
          rel="noreferrer"
          onClick={openMailTo}
        >
          donneespersonnelles@tutelaire.fr
        </a>
        .
        <br />
        Pour plus d&apos;informations relatives à la gestion de vos données,
        vous pouvez à tout moment consulter notre{" "}
        <a
          href="https://www.tutelaire.fr/protection-des-donnees"
          target="_blank"
          rel="noreferrer"
        >
          politique de confidentialité
        </a>{" "}
        sur le site internet{" "}
        <a href="https://www.tutelaire.fr" target="_blank" rel="noreferrer">
          www.tutelaire.fr
        </a>{" "}
        ou la demander par courrier simple au responsable de traitement de
        Tutélaire.
      </small>
    </p>
  );
}

export function FooterLegalTutelaire({ stepName }: FooterLegalTutelaireProps) {
  return (
    <>
      <div className="footer-legal__part">
        <ProtectionDonneesTutelaire />
      </div>

      <div className="footer-legal__part">
        <small>
          Mutuelle soumise aux dispositions du livre II du Code de la mutualité.
        </small>
        <small>
          SIREN 775 682 164.
          <br />
          Siège social : 157 avenue de France
          <br />
          75013 Paris
        </small>

        <div className="footer-legal__logos">
          <img
            className="footer-legal__logo-tutelaire"
            alt="logo tutelaire"
            src="/logo-tutelaire.png"
          />
          <img
            className="footer-legal__logo-mutualite"
            alt="logo mutualité francaise"
            src="/logo-mutualite-francaise.png"
          />
        </div>
        <div className="stepper__mentions-legales">
          <a
            href="https://www.tutelaire.fr/mentions-legales-cookies"
            target="_blank"
            rel="noreferrer"
          >
            Mentions légales
          </a>
        </div>
      </div>
    </>
  );
}
