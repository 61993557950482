import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "@src/App";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (!["local", "production"].includes(process.env.VITE_ENVIRONNEMENT)) {
  Sentry.init({
    dsn: "https://847eb76a85fe4ec494d76e21affafbbb@o4503970878455808.ingest.sentry.io/4503970879897600",
    integrations: [new BrowserTracing()],
    environment: process.env.VITE_ENVIRONNEMENT,
    tracesSampleRate: 1.0,
    release: process.env.VITE_RELEASE,
  });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter>
    <Routes>
      <Route path="/">
        <Route
          path=":projectId/souscription"
          element={<App isSouscription />}
        />
        <Route path=":projectId" element={<App />} />
        <Route path="" element={<App />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
