import classNames from "classnames";
import { forwardRef } from "react";
import "./infoBulleButton.scss";

type InfoBulleButtonProps = {
  children: string;
  size?: "normal" | "large";
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export const InfoBulleButton = forwardRef<
  HTMLButtonElement,
  InfoBulleButtonProps
>(
  (
    {
      children,
      size = "normal",
      onClick,
      onMouseEnter,
      onMouseLeave,
    }: InfoBulleButtonProps,
    ref
  ) => (
    <button
      className={classNames("info-bulle-button", {
        "info-bulle-button--large": size === "large",
      })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={ref}
    >
      {children}
    </button>
  )
);
