import { FooterMUTAMIByStepName } from "@src/Footer/MUTAMI/FooterMUTAMIByStepName";
import { StepName } from "@src/Steps/Steps";
import { MouseEvent } from "react";

type FooterLegalMutamiProps = {
  stepName: StepName;
};

export function ProtectionDonneesMutami() {
  const openMailTo = (e: MouseEvent) => {
    window.open("mailto:donneespersonnelles@tutelaire.fr", "mail");
    e.preventDefault();
  };

  return (
    <p>
      <small>
        Le contrat sâge autonomie vous est proposé par la Mutuelle Mutami,
        Mutuelle régie par les dispositions du Livre II du Code de la mutualité,
        immatriculée au répertoire Sirene sous le n°776 950 677 dont le siège
        social est situé au 70, boulevard Matabiau - CS 46951 - 31069 TOULOUSE
        CEDEX 7. Mutuelle soumise à l&apos;Autorité de contrôle prudentiel et de
        résolution (ACPR : 4 place de Budapest - CS 92459 - 75436 PARIS CEDEX
        09). Elle agit en en qualité de distributeur de Tutélaire, créateur et
        assureur du contrat sâge autonomie.
        <br />
        <span style={{ textDecoration: "underline" }}>
          Données personnelles :
        </span>{" "}
        les informations recueillies dans le présent formulaire, ont pour
        finalité de pouvoir vous rappeler et répondre à vos questions.
        <br />
        Vous disposez des droits d'accès, de rectification, d'opposition, de
        portabilité, d'effacement et de limitation du traitement vous
        concernant.
        <br />
        Pour l'exercice de vos droits, adressez un courrier simple au
        responsable de traitement : Tutélaire, 157 avenue de France, 75013 Paris
        :{" "}
        <a
          href="mailto:donneespersonnelles@tutelaire.fr"
          target="_blank"
          rel="noreferrer"
          onClick={openMailTo}
        >
          donneespersonnelles@tutelaire.fr
        </a>
        .
        <br />
        Pour plus d&apos;informations relatives à la gestion de vos données,
        vous pouvez à tout moment consulter notre{" "}
        <a
          href="https://www.tutelaire.fr/protection-des-donnees"
          target="_blank"
          rel="noreferrer"
        >
          politique de confidentialité
        </a>{" "}
        sur le site internet{" "}
        <a href="https://www.tutelaire.fr" target="_blank" rel="noreferrer">
          www.tutelaire.fr
        </a>{" "}
        ou la demander par courrier simple au responsable de traitement de
        Tutélaire, Mutuelle soumise aux dispositions du livre II du Code de la
        mutualité. SIREN 775 682 164.Siège social : 157 avenue de France 75013
        Paris
      </small>
    </p>
  );
}

export function FooterLegalMutami({ stepName }: FooterLegalMutamiProps) {
  return (
    <>
      <div className="footer-legal__part">
        <FooterMUTAMIByStepName stepName={stepName} />
      </div>
      <div className="footer-legal__part">
        <div className="footer-legal__logos">
          <img
            className="footer-legal__logo-tutelaire"
            alt="logo tutelaire"
            src="/logo-tutelaire.png"
          />
          <img
            className="footer-legal__logo-mutualite"
            alt="logo mutualité francaise"
            src="/logo-mutualite-francaise.png"
          />
        </div>
        <div className="stepper__mentions-legales">
          <a
            href="https://www.mutuelle-mutami.fr/mentions-legales/"
            target="_blank"
            rel="noreferrer"
          >
            Mentions légales
          </a>
        </div>
      </div>
    </>
  );
}
